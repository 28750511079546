<template>
    <section style="width: 100%">
        <div class="field">
            <label class="label">{{ label }}</label>
            <div class="control is-flex">
                <b-dropdown aria-role="list">
                    <button class="button is-light" slot="trigger" >
                        <b-icon icon="calendar"></b-icon>
                    </button>

                    <b-dropdown-item aria-role="listitem"
                                     v-for="opcao in opcoes"
                                     :key="opcao.id"
                                     @click="opcao.acao">{{ opcao.label }}</b-dropdown-item>
                </b-dropdown>
                <b-datepicker editable expanded
                              :placeholder="placeHolder"
                              v-model="dates"
                              ref="datePicker"
                              style="width: 90%"
                              range />
                <b-button @click="dates = []"
                          title="Limpar filtro por data"
                          v-show="dates.length > 0"
                          icon-left="close">
                </b-button>
            </div>
        </div>
    </section>

</template>

<script>
    import moment from "moment";
    export default {
        components: {},
        props: {
            dataInicial: null,
            dataFinal: null,
            label: null,
            placeHolder: null,
            selecionadas: null,
        },
        mounted() {
            if (this.dataInicial && this.dataFinal) {
                this.dates = [this.dataInicial, this.dataFinal];
            }
        },
        data() {
            return {
                dates: [],
                getDataHojeFimDoDia() {
                    return moment()
                        .set("hour", 23)
                        .set("minute", 59)
                        .set("second", 59)
                        .toDate();
                },
                opcoes: [
                    {
                        id: 1,
                        label: "Hoje",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 2,
                        label: "Ontem",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -1)
                                    .toDate(),
                                moment()
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .add("d", -1)
                                    .toDate()
                            ];
                        },
                    },
                    {
                        id: 3,
                        label: "Últimos 7 dias",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -7)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 4,
                        label: "Últimos 30 dias",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -30)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 5,
                        label: "Últimos 60 dias",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -60)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 6,
                        label: "Este mês",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .startOf("month")
                                    .toDate(),
                                moment()
                                    .endOf("month")
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .toDate(),
                            ];
                        },
                    },
                    {
                        id: 7,
                        label: "Mês Passado",
                        acao: () => {
                            this.dates = [
                                moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("month", -1)
                                    .startOf("month")
                                    .toDate(),
                                moment()
                                    .add("month", -1)
                                    .endOf("month")
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .toDate(),
                            ];
                        },
                    },
                ],
            };
        },
        watch: {
            dataInicial(valor) {
                if (valor) {
                    if (this.dataInicial && this.dataFinal) {
                        this.dates[this.dataInicial, this.dataFinal]
                    }
                }
            },
            dataFinal(valor) {
                if (valor) {
                    if (this.dataInicial && this.dataFinal) {
                        this.dates[this.dataInicial, this.dataFinal]
                    }
                }
            },
            dates(novoValor) {
                if (novoValor && novoValor.length) {
                    this.$emit("update:dataInicial", novoValor[0]);
                    this.$emit("update:dataFinal", novoValor[1]);
                    this.$emit("changed", { dataInicial: novoValor[0], dataFinal: novoValor[1] });
                }
            },
            selecionadas(novoValor) {
                if (novoValor && novoValor.length) {
                    this.dates = novoValor;
                }
            },
        }
    };
</script>

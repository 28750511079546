<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <div class="box" v-show="showFilter">
            <div class="columns">
                <div class="column is-6">
                    <div class="columns">
                        <div class="column">
                            <searchIdName :ponto="false"
                                          label="Local de origem"
                                          table="Local"
                                          :id.sync="item.local"></searchIdName>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <searchIdName :ponto="false"
                                          label="Setor"
                                          table="Setor"
                                          :id.sync="item.setor"></searchIdName>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="columns">
                        <div class="column">
                            <periodo label="Período" :dataInicial="item.dataInicial" :dataFinal="item.dataFinal" @changed="dataAlterada" />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Resultados por página">
                                <b-select v-model="item.numeroDeItens" @input="selectNumeroDeItens">
                                    <option v-for="option in perfilQuantidade"
                                            :value="option"
                                            :key="option">
                                        {{ option}}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="item.ordenaPorNome">Ordenar por nome do paciente</b-switch>                            
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-switch v-model="item.filtrarPorExamesUrgentes">Filtrar apenas exames urgentes</b-switch>                            
                        </div>
                    </div>                    
                </div>
                <div class="column is-2">
                    <guiaindicadores :guia="indicadores" editavel></guiaindicadores>
                </div>
            </div>
            <div class="columns">
                <b-button type="is-primary"
                          icon-left="filter"
                          :loading="isLoading"
                          class="center"
                          @click.prevent.stop="filterData">Filtrar</b-button>
            </div>
        </div>

        <form action="/Guia/ColetaDeMateriais" method="post">
            <table class="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Guia</th>
                        <th>Paciente</th>
                        <th colspan="2"></th>
                        <th class="has-text-right">
                            <b-checkbox @input="selecionarTudo"></b-checkbox>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(guia, index) in model.lista" :key="index">
                    <tr class="has-background-grey-lighter">
                        <td>
                            <router-link :to="{ name: 'guia', params: { id: guia.guiaId }}">
                                {{ guia.guiaId }}
                            </router-link>
                        </td>
                        <td width="50%">
                            {{ guia.pacienteNome }}
                        </td>
                        <td colspan="2">
                            <guiaindicadores :guia="guia"></guiaindicadores>
                        </td>
                        <td class="has-text-right">
                            <b-checkbox v-model="guia.selecionado" @click.prevent.native="selecionarGuiaClick(guia)"></b-checkbox>
                        </td>
                    </tr>
                    <tr v-if="guia.guiaObs" class="is-size-7">
                        <td colspan="5">
                            {{ guia.guiaObs }}
                        </td>
                    </tr>
                    <tr v-for="(item, idx) in guia.itens" :key="idx">
                        <td class="has-text-right">
                            <b>
                                {{ item.exameApelido }}
                            </b>
                            <input type="hidden" :name="'Guias[' + item.index + '].GuiaId'" :value="item.guiaId" />
                            <input type="hidden" :name="'Guias[' + item.index + '].GuiaExameItem'" :value="item.guiaExameItem" />
                            <input type="hidden" :name="'Guias[' + item.index + '].PacienteNome'" :value="item.pacienteNome" />
                            <input type="hidden" :name="'Guias[' + item.index + '].ExameApelido'" :value="item.exameApelido" />
                            <input type="hidden" :name="'Guias[' + item.index + '].ExameSolicitacao'" :value="item.exameSolicitacao" />
                            <input type="hidden" :name="'Guias[' + item.index + '].ExameObs'" :value="item.exameObs" />
                            <input type="hidden" :name="'Guias[' + item.index + '].ExameStatus'" :value="item.exameStatus" />
                            <input type="hidden" :name="'Guias[' + item.index + '].Entrega'" :value="item.selecionado" />
                        </td>
                        <td>
                            {{ item.exameSolicitacao | datahora }}
                        </td>
                        <td class="has-text-centered">
                            {{ item.exameObs }}
                            <b-icon v-if="item.urgente" icon="alert-circle" type="is-danger" title="Exame urgente"></b-icon>
                        </td>
                        <td class="has-text-centered">
                            <span class="tag" :title="item.statusTitle" :style="item.statusHtmlStyle">
                                {{ item.statusSigla }}
                            </span>
                        </td>
                        <td class="has-text-right">
                            <b-checkbox v-model="item.selecionado"></b-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>

            <b-pagination :total="total"
                          v-model="page"
                          v-show="total > numeroDeItens"
                          :per-page="numeroDeItens"
                          @change="onPageChange"
                          aria-next-label="Próxima página"
                          aria-previous-label="Página anterior"
                          aria-page-label="Página"
                          aria-current-label="Página atual">
            </b-pagination>

            <nav class="notification is-light level" v-show="config.permiteSelecionarOutroResponsavelPelaColeta">
                <div class="column is-12">
                    <b-field label="Funcionário Responsável">
                        <b-select v-model="model.funcionarioId" expanded name="funcionarioId">
                            <template v-for="(item, index) in this.funcionarios">
                                <option v-if="model.funcionarioId == item.id" selected v-bind:key="index" :value="item.id">
                                    {{item.nome}}
                                </option>
                                <option v-else v-bind:key="index" :value="item.id">
                                    {{item.nome}}
                                </option>
                            </template>
                        </b-select>
                    </b-field>
                </div>
            </nav>

            <nav class="notification is-light level">
                <div class="level-item">
                    <p class="buttons">
                        <b-button type="is-success"
                                  native-type="submit"
                                  :loading="isLoading"
                                  icon-left="check-circle">
                            Enviar
                        </b-button>
                        <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                            Cancelar
                        </b-button>
                    </p>
                </div>
            </nav>
        </form>

    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodo from '@/components/periodo.vue'
    import moment from 'moment';
    import guiaindicadores from '@/components/atendimento/guiaIndicadores.vue'

    export default {
        data() {
            return {
                model: {
                    lista: [],
                    funcionarioId: null
                },
                item: {
                    unidadeId: this.$route.query?.unidadeId ?? null,
                    local: this.$route.query?.localId ?? null,
                    setor: this.$route.query?.setorId ?? null,
                    guiaId: this.$route.query?.guiaId ?? null,
                    dataInicial: this.$route.query?.dataInicial ?? null,
                    dataFinal: this.$route.query?.dataFinal ?? null,
                    numeroDeItens: this.$route.query?.numeroDeItens ?? 50,
                    ordenaPorNome: true,
                    filtrarPorExamesUrgentes: false,
                    item: this.$route.query?.item ?? null
                },
                funcionarios: [],
                total: 0,
                perPage: 50,
                numeroDeItens: 50,
                isLoading: false,
                ordenarPor: 'Nome',                
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                selecionado: [],
                indicadores: {
                    sepse: this.$route.query?.sepse ?? false,
                    dorToracica: this.$route.query?.dorToracica ?? false,
                    avc: this.$route.query?.avc ?? false,
                    traumatologia: this.$route.query?.traumatologia ?? false,
                    prestesAReceberAltaMedica: this.$route.query?.prestesAReceberAltaMedica ?? false,
                    guiaPendente: this.$route.query?.guiaPendente ?? false,
                    necessidadesEspeciais: this.$route.query?.necessidadesEspeciais ?? false,
                    guiaConferida: this.$route.query?.guiaConferida ?? false
                },
                perfilQuantidade: ['10', '50', '100'],
                id: null,
                showFilter: true
            }
        },
        components: {
            titlebar,
            searchIdName,
            periodo,
            guiaindicadores
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Coleta por exame'
                ]
            },
            ...mapState([
                'config',
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        filters: {
            datahora: (valor) => {
                if (!valor) return null;

                return moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {
            selecionarTudo(value) {
                this.model.lista.forEach(x => {
                    x.selecionado = value
                    this.selecionarGuia(x)
                })
            },
            selecionarGuia(guia) {
                guia.itens.forEach(x => x.selecionado = guia.selecionado)
            },
            selecionarGuiaClick(guia) {
                guia.selecionado = !guia.selecionado
                this.selecionarGuia(guia)
            },
            filterData() {
                const params = [];

                if (this.item.unidadeId != null) params.push(`unidadeId=${this.item.unidadeId}`);
                if (this.item.local != null) params.push(`localDeOrigemId=${this.item.local}`);
                if (this.page != null) params.push(`page=${this.page}`);

                if (this.item.guiaId != null) {
                    params.push(`guiaId=${this.item.guiaId}`)
                    this.showFilter = false
                }

                if (this.item.item != null) params.push(`item=${this.item.item}`);
                if (this.item.setor != null) params.push(`setorId=${this.item.setor}`);
                if (this.item.dataInicial != null) params.push(`dataInicial=${encodeURIComponent(moment(this.item.dataInicial).format())}`);
                if (this.item.dataFinal != null) params.push(`dataFinal=${encodeURIComponent(moment(this.item.dataFinal).format())}`);
                if (this.item.numeroDeItens != null) params.push(`numeroDeItens=${this.item.numeroDeItens}`);
                if (this.item.ordenaPorNome != null) params.push(`ordenaPorNome=${this.item.ordenaPorNome}`);
                if (this.item.filtrarPorExamesUrgentes != null) params.push(`filtrarPorExamesUrgentes=${this.item.filtrarPorExamesUrgentes}`);

                if (this.indicadores.sepse)
                    params.push('somenteSepse=true')
                if (this.indicadores.dorToracica)
                    params.push('somenteDorToracica=true')
                if (this.indicadores.avc)
                    params.push('somenteAVC=true')
                if (this.indicadores.traumatologia)
                    params.push('somenteTraumatologia=true')
                if (this.indicadores.prestesAReceberAltaMedica)
                    params.push('somentePrestesAReceberAltaMedica=true')
                if (this.indicadores.guiaPendente)
                    params.push('somenteGuiaPendente=true')
                if (this.indicadores.guiaConferida)
                    params.push('somenteGuiaConferida=true')
                if (this.indicadores.necessidadesEspeciais)
                    params.push('somenteNecessidadesEspeciais=true')

                this.isLoading = true
                this.$http.get(`/api/atendimento/ListaColetaDeMateriais?${params.join('&')}`).then(({ data }) => {
                    this.model = data;
                    if (this.model.lista.length == 0 && this.page > 1) {
                        this.page = 1
                        this.filterData()
                    }
                    const guias = [];
                    let index = 0
                    data?.lista?.forEach(i => {
                        i.index = index
                        i.selecionado = this.$route.query?.guiaId != null ?? false
                        let guia = guias.find(g => g.guiaId === i.guiaId)
                        if (guia == null) {
                            guia = {
                                ...i,
                                itens: []
                            }
                            guias.push(guia)
                        }
                        guia.itens.push(i)
                        index++
                    })
                    this.model.lista = guias;

                    this.total = this.model?.pageCount * this.item.numeroDeItens;
                    this.$router.replace({ query: { ...this.$route.query, page: this.page } });
                }).catch((error) => {
                    this.model = []
                    this.total = 0
                    throw error
                }).finally(() => {
                    this.isLoading = false
                })

            },
            onPageChange(page) {
                this.page = page;
                console.log('PAGINA: ' + page);
                this.$router.replace({ query: { ...this.$route.query, page: this.page } });
                this.filterData();
            },
            dataAlterada({ dataInicial, dataFinal }) {
                this.item.dataInicial = dataInicial;
                this.item.dataFinal = dataFinal;
                this.$router.replace({ query: { ...this.$route.query, page: this.page, dataInicial: moment(dataInicial).format('DD/MM/YYYY HH:mm:ss'), dataFinal: moment(dataFinal).format('DD/MM/YYYY HH:mm:ss') } });
            },
            selectNumeroDeItens() {
                this.page = 1;
                this.$router.replace({ query: { ...this.$route.query, numeroDeItens: this.item.numeroDeItens, page: this.page } });
            },
            enviar() {
                // TODO: implementar o redirecionamento a tela de pesquisa de coleta
                // ESSE BLOCO TODO NÃO ESTÁ EM EXECUÇÃO, JÁ QUE ESTÁ SENDO ENVIADO UM SUBMIT DO FORM DIRETO PARA O GUIACONTROLLER (ANTIGOS)
                const guias = this.model?.lista?.flatMap(x => x.itens).filter(i => i.selecionado)?.map(i => {
                    return {
                        guiaId: i.guiaId,
                        guiaExameItem: i.guiaExameItem,
                        guiaObs: i.guiaObs,
                        pacienteNome: i.pacienteNome,
                        exameApelido: i.exameApelido,
                        exameSolicitacao: i.exameSolicitacao,
                        exameObs: i.exameObs,
                        guiaStatusExame: i.guiaStatusExame,
                        urgente: i.urgente,
                        entrega: true
                    };
                });
                if (!guias.length) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Ao menos uma guia precisa ser selecionada para envio',
                        type: 'is-danger',
                        queue: false
                    })

                    return;
                }
                const funcionarioId = this.model.funcionarioId;
                const ordenaPorNome = this.item.ordenaPorNome;
                this.isLoading = true
                this.$http.post(`/guia/ColetaDeMateriais?funcionarioId=${funcionarioId}&ordenaPorNome=${ordenaPorNome}`, guias).then((response) => {
                    if (response.status === 200) {
                        const hasMoreThanOneGuia = (guias) => {
                            let guiaId = null;
                            let guiaCounter = 0;
                            guias.forEach(g => {
                                if (g.guiaId != guiaId) {
                                    guiaCounter++;
                                }
                                guiaId = g.guiaId;

                            });
                            return guiaCounter > 1;
                        }
                        if (!hasMoreThanOneGuia(guias)) {
                            this.$router.push({ name: 'guia', params: { id: guias[0].guiaId } });
                            return;
                        } else {
                            this.$router.push({ name: 'atendimento' });
                        }
                    }
                }).finally(() => {
                    this.isLoading = false
                })
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.funcionarios = data;
                    }
                });

            this.filterData()
        }
    }
</script>
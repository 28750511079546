export default {
    LANGUAGE:{
        NAME:"Portugues"
    },
    PORTAL:{
        SAUDACOES:"Olá,",
        INSTRUCOES: "Por esta página você poderá acompanhar todos os exames solicitados neste laboratório.",
        PESQUISA: "Para melhorar nossos serviços, pedimos a gentileza de responder as seguintes pesquisas",
        ANALISE: "Análises clínicas",
        RADIOLOGIA: "Radiologia",
        NUMEROACESSO: "Número de acesso",
        VIEWER: "Viewer",
        DATAEMISSAO: "Data de emissão",
        RESULTADO: "Resultado",

    },
    GUIAS:{
        DATAEMISSAO: "Data de emissão",
        NUMEROGUIA: "Número da Guia",
        PACIENTENOME: "Nome do Paciente",
        FILTROS: "Filtros",
        GUIA: "Guia",
        RETORNO: "Retorno",
        PACIENTE: "Paciente",
        RACAESPECIE: "Raça/Espécie",
        MEDICO: "Médico",
        DATASOLICITACAO: "Data de solicitação",
        DATAENTREGA: "Data de entrega",
        DATAPREVISTA: "Data prevista",
        PRECO: "Preço",
        AUTORIZACAO: "Sem autorização do convênio!",
        CONTATO: "Por favor, entre em contato com o laboratório.", 
        ABRIRRESULTADOS: "Abrir resultados",
        EDITAR: "Editar guia",
        ETIQUETA: "Imprimir etiqueta",
        COMPROVANTE: "Imprimir recibo",
        ENVIAEMAIL: "Enviar por email",
        VERMAIS: "Clique para ver mais informações de cada exame",
        PRONTOS: "Exames prontos",
        SEMRESULTADO: "Não foram encontrado exames para os filtros selecionados!",
    },
    PESQUISASATISFACAO:{
        TITULOPORTAL: "Portal",
        TITLEPESQUISA: "Pesquisa de Satisfação",
        SALVAR: "Salvar",
        VOLTAR: "Voltar",
    },
    FILTROS:{
        FILTRO: "Filtros",
        PACIENTE: "Paciente",
        PERIODO: "Período",
        PROCURAR: "Procurar por nome/Data de nascimento/Id da guia",
        INTEGRADORES: "Integradores",
        INTEGRADOR: "Integrador",
        LOCAL: "Local",
        CONVENIO: "Convenio",
        MEDICO: "Doctor",
        TODOS: "Todos",
        PARCIAIS: "Parciais",
        PRONTOS: "Prontos",
        ATRSADOS: "Atrasados",
        RECOLETA: "Recoleta",
        FILTRAR: "Filtrar por",
        DATASOLICITACAO: "Data solicitação",
        DATARETORNO: "Data retorno",
        DATAASSINATURA: "Data assinatura",
        RESULTADOIMPRESSO: "Somente resultados não impressos",
        EXAMEPORTAL: "Somente exames do portal",
        ORDEM: "Ordenar por",
        DATAEMISSAO: "Data de emissão",
        NUMEROGUIA: "Numero da guia",
        PACIENTENOME: "Nome do Paciente",
        MODIFICACAO: "Última modificação",
        SITUACAO: "Situação",
        PEDIDOSLOTE: "Pedidos sem lote",
        LOTESENVIADO: "Lotes enviados",
        LOTENAORECEBIDO: "Lotes enviados e não recebidos",
        LOTERECEBIDO: "Lotes recebidos",
        AMOSTRAIMPRESSA: "Amostras não impressas",
        BUSCAR: "BUSCAR",
        CANCELAR: "CANCELAR"
    },
    ORCAMENTO:{
        EXAMES: "Exames",
        EXAMESENCONTRADOS: "Nenhum exame encontrado",
        EXAME: "Exame",
        APELIDO: "Apelido",
        NOME: "Nome",
        PRECO: "Preço",
        TOTALEXAME: "Total de exames",
        BLOQUEADO: "Bloqueado",
    },
    PACIENTESCONVENIO:{
        ID: "Id",
        BUSCARPORNOME: "Procurar por nome",
        INCLUIR: "Incluir",
        NOME: "Nome",
        SEXO: "SEXO",
        NASCIMENTO: "Nascimento",
        INSCRICAO: "Inscrição",
        CPF: "CPF"
    },
    LOTES:{
        CODIGOBARRAS: "Código de barras",
        INCLUIR: "Incluir",
        INCLUIRPENDENCIA: "Incluir pendente",
        CONTADOR: "Contador",
        AMOSTRA: "Amostra",
        PACIENTE: "Paciente",
        MATERIAL: "Material",
        CONSERVANTE: "Conservante",
        EXAMES: "Exames",
        TEMPERATURA: "Temperatura",
        LATITUDE: "Latitude",
        LONGITUDE: "Longitude",
        ENVIAR: "Enviar",
    },
    CATALOGOEXAMES:{
        CATALOGOEXAMES: "Procurar por apelido/nome",
        CODIGO: "Código",
        ID: "Id",
        NOME: "Nome",
        APELIDO: "Apelido",
        VISUALIZAR: "Visualizar",
    },
    AMOSTRAS: {
        SELECIONARAMOSTRA: "Selecionar/deselecionar todas amostras",
        IMPRIMIRAMOSTRA: "Imprimir amostra",
        FILTROS: "Filtros",
        EXAMESPERIODO: "Exames pedidos no período de "
    },
    AMOSTRA: {
        AMOSTRACANCELADA: "Amostra cancelada",
        AMOSTRAEXAMECANCELADA: "Amostra com todos os exames cancelados",
       AMOSTRAINCIDENCIA: "Amostra com incidência",
       CODIGOBARRAS: "Código de barras",
       PACIENTE: "Paciente",
       MATERIAL: "Material",
       CONSERVANTE: "Conservante",
       LOTE: "Lote",
       CONGELADO: "Acondicionamento: Congelado",
       REFRIGERADA: "Acondicionamento: Refrigerado",
       AMBIENTE: "Acondicionamento: Ambiente",
       ETIQUETA: "Acondicionamento: Etiqueta",
       EXAMEASSINADO: "Exame assinado",
       EXAMEDIGITADO: "Exame digitado",
       EXAMECANCELADO: "Exame cancelado",
       IDENTIFICACAO: "Identificação"
    },
    RELACAOEXAME: {
        SOLICITADO: "Solicitado em",
        TOTAIS: "Totais",
        GUIAS: "Guias",
        EXAMES: "Exames",
        EXAMESPERIODO: "Exames pedidos no período de "
    },
    VISUALIZAREXAMES:{
        APELIDO: "Apelido",
        NOME: "Nome",
        QTDEAMOSTRAS: "Qtde. amostras", 
        PRAZODIA: "Prazo dias",
        PRAZOHORA: "Prazo horas",
        PRAZOMINUTO: "Prazo minutos",
        TEMPOJEJUM:  "Tempo mínimo de jejum",
        VOLUMEMINIMO: "Volume mínimo",
        MEDICAMENTO: "Medicamento",
        DUM:"Data da última menstruação",
        PESO: "Peso",
        ALTURA: "Altura",
        MASCARAS: "Visualizar máscaras",
        VERSÃO: "Versão",
        SINONIMIA: "Sinonímia",
        RECIPIENTES: "Recipientes",
        OBTERAMOSTRA: "Obtenção de amostra",
        INFOLABORATORIO: "Informações ao laboratório",
        INFOPACIENTE: "Informações ao paciente",
        APLICACAOOCLINICA: "Aplicação clínica / interpretação",
        CRITREJEICAO: "Critérios de rejeição",
        TABELA: "Tabela de preço",
        BLOQUEADO: "Bloqueado",
        NEGOCIADO: "Negociado",
        VOLTAR: "Voltar",
        CODIGO: "Código",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        ESTABILIDADEAMBIENTE: "Estabilidade ambiente (h)",
        ESTABILIDADEREFRIGERADO: "Estabilidade refrigerado (h)",
        ESTABILIDADECONGELADO: "Estabilidade congelado (h)",
    },
    RESULTADOS: {
        IMPRIMIRRESULTADO: "Imprimir resultados",
        GUIA: "Guia",
        REFERENCIA: "Referência",
        EMISSAO: "Emissão",
        PACIENTEID: "Paciente Id",
        PACIENTENOME: "Paciente nome",
        LOCAL: "Local",
        LIBERACAO: "Liberação",
        NENHUM: "Nenhum",
        PARCIAL: "Parcial",
        TOTAL: "Total",
        EXAMESPERIODO: "Exames pedidos no período de "
    },
    AVISOS: {
        PEDIDO: "Pedido", 
        AMOSTRA: "Amostra",
        TIPO: "Tipo",
        EXAMES: "Exames",
    },
    RECOLETAS: {
       PEDIDO: "Pedido", 
       CODIGOBARRAS: "Código de barras",
       SOLICITADA: "Solicitada em",
       MOTIVO: "Motivo",
       EXAMES: "Exames",
       ETIQUETA: "Imprimir etiqueta",
    },
    NAVBARCONVENIO: {
        GUIAS: "Guias",
        ORCAMENTO: "Orçamento",
        INCLUIRGUIA: "Incluir guia",
        ENVIARLOTE: "Enviar lote",
        CATALOGO: "Catálogo",
        DEEXAMES: "de exames",
        AMOSTRAS: "Amostras",
        EXAMES: "Exames",
        RESULTADOS: "Resultados",
        AVISOS: "Avisos",
        RECOLETA: "Recoleta",
        REMARCACAO: "Remarcação",
        VALORESCRIT: "Valores críticos",
        INTEGRACAO: "Integrações",
    },
    VALORCRITICO: {
        PEDIDO: "Pedido",
        EXAMES: "Exames",
    },
    GUIA:{
        INDENTIFICACAO: "Identificação",
        REFERENCIA: "Referência",
        LOCALORIGEM: "Local de origem",
        SELECIONELOCAL: "Selecione o local de origem",
        EMITIDA: "Emitida em",
        PACIENTE: "Paciente",
        HORASJEJUM: "Horas de jejum",
        INSCRICAO: "Inscripción",
        SEXO: "SEXO",
        NASCIMENTO: "Nascimento",
        MEDICO: "Médico",
        CBO: "CBO-S",
        EXAMES: "Exames",
        AMOSTRAS: "Amostras",
        INCLUIREXAME: "Incluir exame",
        PROCURAR: "Procurar por",
        APELIDO: "Apelido",
        NOME: "Nome",
        CÓDIGO: "Código externo",
        IDENTIFICACAO: "Identificação",
        NENHUMEXAME: "Nenhum exame disponível.",
        BLOQUEADO: "",
        JEJUM: "",
        CANCELARSELECIONADOS: "Cancelar exames selecionados",
        INCLUIR: "Incluir",
        IMPRIMIRCOMPROVANTE: "Imprimir comprovante",
        COMPROVANTE: "Comprovante",
        OBSERVACOES: "Observações",
        HIPOTESE: "Hipótese diagnóstica",
        CID10: "CID10",
        INDICACAO: "Indicação clínica",
        MEDICAMENTOS: "Medicamentos",
        INCLUIRMEDICAMENTO: "Inclua um medicamento",
        RESPOSTAS: "Respostas",
        NENHUMARESPOSTA: "Não existe nenhuma resposta",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MEDICOOBRIGATORIO: "Médico obrigatório",
        MESSAGEMEDICO: "Especifique um médico para continuar.",
        LOCALOBRIGATORIO: "Local de origem obrigatório",
        MENSAGEMLOCAL: "Especifique um lugar de origem para continuar.",
        EXAMEOBRIGATORIO: "Exames obrigatórios",
        MENSAGEMEXAME: "A guia precisa ter pelo menos 1 exame.",
        MEDICAMENTOOBRIGATORIO: "Medicamento obrigatório",
        MENSAGEMMEDICAMENTO: "Estes exames precisam que informe os <b>medicamentos</b> em uso.",
        PESOOBRIGATORIO: "Peso obrigatório",
        MENSAGEMPESO: "Confirma a inclusão do peso do paciente ?",
        ALTURAOBRIGATORIA: "Altura obrigatória",
        MENSAGEMALTURA: "Confirma a inclusão da altura do paciente ?",
        CONFIRMA: "Confirmar",
        EXAMEINCLUIDO: "Exame já incluído",
        MENSAGEMINCLUIDO: "Confirma a inclusão do exame: ",
        BLOQUEIOEXAME: "Bloqueio de exame duplicado ativo",
        MESSAGEMBLOQUEIO: "Para inserir este exame mais de uma vez, solicite a remoção do bloqueio de exame duplicado, no cadastro de exames",
        BLOQUEIOEXAMEGENERO: "Bloqueio de exame por gênero",
        BLOQUEIOEXAMEGENEROMENSAGEM: "Este exame não pode ser escolhido para este paciente.",
        INSERIREXAME: "Inclusão de exame",
        MENSSAGEMINSERIREXAME: "Já foi encontrado o exame",
        NESTAGUIA: "nesta guia",
        EXAMECOMPLEMENTARMENSAGEM: "O exame complementar ",
        NAOPODEINSERIR: " não pôde ser inserido para este paciente.",
    },
    PACIENTE: {
        NOME: "Nome",
        FONE: "Fone",
        CELULAR: "Celular",
        SEXO: "Sexo",
        SELECIONARSEXO: "Selecione o sexo",
        FEM: "Feminino",
        MASC: "Masculino",
        NASCIMENTO: "Nascimento",
        IDADE: "Idade",
        CALCULARIDADE: "Calcular idade em",
        ANOS: "Anos",
        MESES: "Meses",
        DIAS: "Dias",
        PESO: "Peso (kg)",
        ALTURA: "Altura (cm)",
        EMAIL: "Email",
        RG: "RG",
        CPF: "CPF",
        CONVENIO: "Cartão nacional de saÚde",
        INSCRICAO: "Inscrição",
        NOMEMAE: "Nome da mãe",
        RACA: "Raça",
        ESPECIE: "Espécie",
        RESPONSAVEL: "Responsável",
        OBSERVACOES: "Observações",
        USUARIO: "Usuario",
        CAMERA: "Câmera",
        CAMERAMENSAGEM: "Clique para capturar pela webcam",
        ARQUIVO: "Arquivo",
        USUARIOWEB: "Criar usuário web",
        CANCELARUSUARIO: "Cancelar usuário web",
        INDICACAO: "Indicação",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        MENSAGEMNASCIMENTO: "Valor informado para a data de nascimento inválido!",
        MENSAGEMIDADE: "Valor informado para a idade inválido!",
        EMAILERRO: "O e-mail ",
        USADO: " já está sendo usado pelo usuario do ",
        CODIGOUSADO: " código ",
        RACAOBRIGATORIO: "Raça obrigatória.",
        ESPECIEOBRIGATORIO: "Especie obrigatória.",
        ESPECIEINVALIDA: "Especie inválida",
        RESPONSAVELOBRIGATORIO: "Responsável obrigatório",
        CPFOBRIGATORIO: "CPF obrigatório",
        USARIOWEBNAOCRIADO: "Não é possível criar usuário web sem email.",
        CPFUSADO: "Este CPF já está em uso.",
        ERROSALVAR: "Erro ao salvar paciente, verificando cpf: ",
        ERROSALVARPACIENTE: "Erro ao salvar paciente: ",
    },
    BUSCACEP: {
        CEP: "CEP",
        CEPMESSAGEM: "CEP não encontrado",
        ENDERECO: "Endereço",
        BAIRRO: "Bairro",
    },
    BUSCACIDADE: {
        CIDADE: "Cidade",
        NENHUMACIDADE: "Nenhuma cidade selecionada",
    },
    EXAMESATRASADOS:{
        PEDIDO: "Pedido", 
        REFERENCIA: "Referência",
        PACIENTE: "Paciente",
        EXAMES: "Exames",
        MOTIVO: "Motivo",
    },
    INTEGRACAO: {
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        DATA: "Data",
        MENSAGEM: "Mensagem",
    },
    WORKFLOW: {
        PACIENTE: "Paciente",
        DATARETORNO: "Data retorno",
        ETIQUETA: "Etiqueta",
        IMPRIMIRETIQUETA: "Imprimir etiqueta",
        COMPROVANTE: "Comprovante",
        IMPRIMIRCOMPROVANTE: "Imprimir comprovante",
        VINCULARETIQUETA: "Vincular etiquetas pré-coleta",
        SALVAR: "Salvar",
        MENSAGEMVINCULO: "Vínculo(s) realizado(s) com sucesso.",
        ERROIMPRIMIR: "Erro na impressão de etiquetas: ",
    },
    COMPROVANTEVARIAVEL:{
        MENSAGEMIMPRESSAO: "Erro na impressão de comprovante: "
    },
    MODALUSUARIO: {
        CONFIGUSUARIO: "Configuração do Usuário",
        VIDEOS: "Vídeos de treinamento",
        AGENTE: "Agente de impressão",
        ALTERARSENHA: "Alterar senha",
        SAIRSISTEMA: "Sair do sistema",
        FECHAR: "Fechar",
    },
    MODALIMPRESSAO: {
        IMPRESSAO: "Impressão",
        CONEXAO: "Conexão",
        IMPRIMIR: "Imprimir",
        FECHAR: "Fechar",
        SALVAR: "Salvar",
    },
    MODALCANCELANDOEXAME: {
        CANCELAREXAME: "Cancelar exame",
        ATENCAO: "Atenção!",
        AVISOCANCELAMENTO: "Este procedimento irá cancelar os exames selecionados",
        EXAMES: "Exames",
        MOTIVOS: "Motivos",
        CONFIRMARCANCELAMENTO: "Confirmar cancelamento",
        CANCELAR: "Cancelar",
    },
    MODALRESPOSTAPESQUISA: {
        NOMEARQUIVO: "Nome do arquivo",
        CLIQUEDOWNLOAD: "Clique para fazer o download do arquivo",
        BAIXARARQUIVO: "Baixar arquivo",
        ALTERARARQUIVO: "Alterar arquivo",
        SALVAR: "Salvar pesquisas",
    },
    GUIAEXAME: {
        RESULTADOVINCULADO: "'Resultado de exame vinculado ao item ",
        GUIAVINCULADO: " da guia ",
        EXCLUIREXAME: "Excluir o exame",
        LIGADESLIGAURGENCIA: "Clique ligar/desligar a urgência",
        LIGADESLIGAMATERIAL: "Clique ligar/desligar o recebimento do material",
        AUTORIZACAOCONVENIO: "Este exame requer autorização do convênio!"
    },
    CADASTROGENERICO: {
        NENHUMEXAME: "Nenhum exame encontrado",
        TOTAL: "Total"
    }
}
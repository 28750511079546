<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns ">
                <div class="column is-3">
                    <b-field label="Unidade">
                        <b-select v-model="unidadeId" @input="loadData"
                                  expanded>
                            <option :value="null">Todas</option>
                            <option v-for="(item, index) in unidades"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Departamento">
                        <b-select v-model="departamentoId" @input="loadData"
                                  expanded>
                            <option :value="null">Todos</option>
                            <option v-for="(item, index) in departamentos"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Setor">
                        <b-select v-model="setorId" @input="loadData"
                                  expanded>
                            <option :value="null">Todos</option>
                            <option v-for="(item, index) in setores"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>                
                <div class="column is-3">
                    <b-field label="Convenio">
                        <b-select v-model="convenioId" @input="loadData"
                                  expanded>
                            <option :value="null">Todos</option>
                            <option v-for="(item, index) in convenios"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns ">
                <div class="column is-3">
                    <b-field label="Local">
                        <b-select v-model="localId" @input="loadData"
                                  expanded>
                            <option :value="null">Todos</option>
                            <option v-for="(item, index) in locais"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Visualizar">
                        <b-select v-model="dias" @input="loadData" expanded>
                            <option value="1">últimas 24 horas</option>
                            <option value="2">últimos 2 dias</option>
                            <option value="3">últimos 3 dias</option>
                            <option value="5">últimos 5 dias</option>
                            <option value="7">últimos 7 dias</option>
                            <option value="10">últimos 10 dias</option>
                            <option value="15">últimos 15 dias</option>
                            <option value="20">últimos 20 dias</option>
                            <option value="30">últimos 30 dias</option>
                            <option value="45">últimos 45 dias</option>
                            <option value="60">últimos 60 dias</option>
                            <option value="90">últimos 90 dias</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Atualizar">
                        <b-select v-model="intervalo" @input="setTimer" expanded>
                            <option value="0">Nunca</option>
                            <option value="1">Cada minuto</option>
                            <option value="2">Cada 2 minutos</option>
                            <option value="5">Cada 5 minutos</option>
                            <option value="10">Cada 10 minutos</option>
                            <option value="20">Cada 20 minutos</option>
                            <option value="30">Cada 30 minutos</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                </div>                
            </div>
        </div>
        <b-notification  v-show="!isLoading && lista.length == 0">Não foram encontradas guias com os filtros selecionados</b-notification>
        <b-table :data="lista"
                 :loading="isLoading"
                 striped
                 hoverable
                 :row-class="(row, index) => calculaClasse(row.dataDeRetorno)">

            <template slot-scope="props" class="">
                <b-table-column field="guiaId" label="Guia">
                    <router-link :to="'/atendimento/guia/'+props.row.guiaId" class="tag is-info is-small is-pulled-right">
                        {{ props.row.guiaId }}
                    </router-link>
                </b-table-column>

                <b-table-column field="Paciente" label="Paciente">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column field="Convenio" label="Convênio">
                    {{ props.row.convenioNome }}
                </b-table-column>

                <b-table-column field="Local" label="Local">
                    {{ props.row.localNome }}
                </b-table-column>

                <b-table-column field="Setor" label="Setor">
                    {{ props.row.setorNome }}
                </b-table-column>

                <b-table-column field="Exames" label="Exames" ellipsis>
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column field="Retorno" label="Retorno previsto">
                    {{ $moment(props.row.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column field="Tempo" label="">
                    {{ calculaTempo(props.row.dataDeRetorno) }}
                </b-table-column>

            </template>
        </b-table>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                dias: 1,
                unidadeId: null,
                departamentoId: null,
                convenioId: null,
                localId: null,
                setorId: null,
                intervalo: 0,
                timer: null,
                isLoading: false,
                lista: [],
                setores: [],
                convenios: [],
                locais: [],
                departamentos: []
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() { 
                return [
                    'Análise',
                    'Entregas'
                ]
            },
            ...mapState([
                'config',
                'unidades'
            ])
        },
        mounted() {
            this.loadDepartamentos();
            this.loadSetores();
            this.loadConvenios();
            this.loadLocais();
            this.loadData();
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },
        methods: {
            calculaClasse(dataDeRetorno){
                let diff = new Date(dataDeRetorno).getTime() - new Date().getTime();

                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                diff -=  days * (1000 * 60 * 60 * 24);

                const hours = Math.floor(diff / (1000 * 60 * 60));
                diff -= hours * (1000 * 60 * 60);

                if(days < 0){
                    return 'analise-entregas-atraso'
                }
                else if(days == 0 && hours < 2){
                    return 'analise-entregas-alerta'
                }
                else {
                    return ''
                }
            },
            calculaTempo(dataDeRetorno){
                let diff = new Date(dataDeRetorno).getTime() - new Date().getTime();

                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                diff -=  days * (1000 * 60 * 60 * 24);

                const hours = Math.floor(diff / (1000 * 60 * 60));
                diff -= hours * (1000 * 60 * 60);

                const mins = Math.floor(diff / (1000 * 60));
                diff -= mins * (1000 * 60);

                const seconds = Math.floor(diff / (1000));
                diff -= seconds * (1000);

                return days + " dias, " + hours + " horas, " + mins + " minutos, " + seconds + " segundos";
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadData, this.intervalo * 60 * 1000)
                }
            },
            loadDepartamentos(){
                this.$http.get('/api/search/departamentos')
                    .then(res => {
                        this.departamentos = res.data;
                    });
            },
            loadSetores(){
                this.$http.get('/api/search/setores')
                    .then(res => {
                        this.setores = res.data;
                    });
            },
            loadConvenios(){
                this.$http.get('/api/search/convenios')
                    .then(res => {
                        this.convenios = res.data;
                    });
            },
            loadLocais(){
                this.$http.get('/api/search/LocaisDeOrigem')
                    .then(res => {
                        this.locais = res.data;
                    });
            },
            loadData(){
                const params = [
                    `dias=${this.dias}`
                ]

                if(this.unidadeId){
                    params.push('unidadeId=' + this.unidadeId);
                }
                if(this.departamentoId){
                    params.push('departamentoId=' + this.departamentoId);
                }
                if(this.setorId){
                    params.push('setorId=' + this.setorId);
                }
                if(this.convenioId){
                    params.push('convenioId=' + this.convenioId);
                }
                if(this.localId){
                    params.push('localId=' + this.localId);
                }

                this.isLoading = true
                this.$http.get(`/api/analitico/examesEntregas?${params.join('&')}`)
                    .then(({ data }) => {
                        this.lista = data;
                        this.isLoading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.isLoading = false
                        throw error
                    })
            }
        }
    }

</script>
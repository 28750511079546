var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('titlebar',{attrs:{"title-stack":_vm.titleStack}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.salvandoDados)?_c('div',{staticClass:"notification is-info"},[_c('button',{staticClass:"delete"}),_vm._v(" A manutenção está sendo salva, este processo pode levar alguns segundos. ")]):_vm._e(),_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent is-vertical is-12"},[_c('article',{staticClass:"tile is-child box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"model.nomeTecnico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Nome Tecnico","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.model.nomeTecnico),callback:function ($$v) {_vm.$set(_vm.model, "nomeTecnico", $$v)},expression:"model.nomeTecnico"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"model.dataAgendada"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Data Agendada","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-datetimepicker',{model:{value:(_vm.model.dataAgendada),callback:function ($$v) {_vm.$set(_vm.model, "dataAgendada", $$v)},expression:"model.dataAgendada"}})],1)]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.id != 0 && _vm.model.id != null),expression:"model.id != 0 && model.id != null"}],staticClass:"column"},[_c('b-field',{attrs:{"label":"Funcionario"}},[_c('b-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.model.funcionarioNome),callback:function ($$v) {_vm.$set(_vm.model, "funcionarioNome", $$v)},expression:"model.funcionarioNome"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Descrição"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.descricao),expression:"model.descricao"}],staticClass:"textarea",attrs:{"rows":"4"},domProps:{"value":(_vm.model.descricao)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "descricao", $event.target.value)}}})])],1)])])])]),_c('br'),_c('nav',{staticClass:"level"},[_c('div',{staticClass:"level-item"},[_c('p',{staticClass:"buttons"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInRole('interface-manutencao')),expression:"isInRole('interface-manutencao')"}],attrs:{"type":"is-success","loading":_vm.salvandoDados,"native-type":"submit","icon-left":"check-circle"}},[_vm._v(" Salvar ")]),_c('b-button',{attrs:{"type":"is-danger","native-type":"button","icon-left":"close-circle"},on:{"click":function($event){return _vm.$router.push({ path:("/interface/maintenances/" + (_vm.model.equipmentId))});}}},[_vm._v(" Cancelar ")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
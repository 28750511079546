<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    A manutenção está sendo salva, este processo pode levar alguns segundos.
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.nomeTecnico" v-slot="{ errors, valid }">
                                        <b-field label="Nome Tecnico"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input type="text" v-model="model.nomeTecnico"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.dataAgendada" v-slot="{ errors, valid }">
                                        <b-field label="Data Agendada" 
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                            <b-datetimepicker v-model="model.dataAgendada"></b-datetimepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column" v-show="model.id != 0 && model.id != null">
                                    <b-field label="Funcionario">
                                        <b-input type="text" v-model="model.funcionarioNome" readonly></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Descrição">
                                        <textarea class="textarea" v-model="model.descricao" rows="4"></textarea>
                                    </b-field>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('interface-manutencao')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.push({ path:`/interface/maintenances/${model.equipmentId}`});">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    funcionarioId: null,
                    funcionarioNome: null,
                    dataAgendada: null,
                    dataCriacao: null,
                    nomeTecnico: null,
                    equipmentId: this.$route.params.equipmentid ?? 0,
                    descricao: null
                },
                salvandoDados: false
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Interface',
                    'Manutenções',
                    this.model.equipmentId,
                    this.model.id ?? 'Novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods:{
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                this.$http.post(`/api/interface/ManutencaoEquipamento`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.push({ path: `/interface/maintenances/${this.model.equipmentId}` })
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            }
        },
        created() {            
            this.$http.get('/api/interface/ManutencaoEquipamento?equipmentId=' + this.model.equipmentId + 
            (this.$route.params.id != null ? '&id=' + this.$route.params.id : ''))
                .then(res => {
                    this.model = res.data;
                    this.model.dataAgendada = new Date(this.model.dataAgendada);
                    this.model.dataCriacao = new Date(this.model.dataCriacao);
                })
        }
    }
</script>
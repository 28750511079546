<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    O container está sendo salvo, este processo pode levar alguns minutos.
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                            
                        </article>
                        <article class="tile is-child box">
                            <div class="columns" v-if="model.posicoes != null && model.posicoes.length > 0">
                                <div class="column">
                                    <label>Posições:</label>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th v-for="(coluna, x) in colunas" v-bind:key="x">
                                                    <input v-model="colunas[x]" class="input" type="text" maxlength="20"/>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(linha, i) in linhas" v-bind:key="i">
                                                <td>
                                                    <input v-model="linhas[i]" class="input" type="text" maxlength="20"/>
                                                </td>
                                                <td v-for="(coluna, j) in colunas" v-bind:key="j">
                                                    <span>{{linha}}{{coluna}}</span>
                                                    <input type="hidden" :value="model.posicoes[(i * colunas.length + j)].numero = linhas[i]"/>
                                                    <input type="hidden" :value="model.posicoes[(i * colunas.length + j)].letra = colunas[j]"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>   
                            <div v-else class="columns">
                                <div class="column is-5">
                                    <label>Quantidade de colunas:</label>
                                    <input v-model="qtdeColunas" class="input" type="text"/>
                                </div>
                                <div class="column is-5">
                                    <label>Quantidade de linhas:</label>
                                    <input v-model="qtdeLinhas" class="input" type="text"/>
                                </div>
                                <div class="column is-2">
                                    <br>
                                    <button class="button is-light" @click="geraPosicoes()">Gerar Posições</button>
                                </div>
                            </div>                            
                        </article>
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('sorotecacontainer-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    posicoes: []
                },
                salvandoDados: false,
                qtdeColunas: null,
                qtdeLinhas: null,
                colunas: [],
                linhas: [],
                alfabeto: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
            }
        },
        created() {
            this.$http.get('/api/manutencao/sorotecacontainer?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;

                    this.geraColunasLinhas();
                });
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Soroteca - Container',
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/sorotecacontainer`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            retornaColuna(posicao) {
                if (posicao >= this.alfabeto.length) {
                    return this.alfabeto[posicao - this.alfabeto.length] + (posicao % this.alfabeto.length);
                } else {
                    return this.alfabeto[posicao];
                }
            },
            geraColunasLinhas(){
                if (this.model.posicoes != null)
                {
                    this.colunas = Array.from(this.groupBy(this.model.posicoes, posicao => posicao.letra).keys());
                    this.linhas = Array.from(this.groupBy(this.model.posicoes, posicao => posicao.numero).keys());
                }
            },
            geraPosicoes(){
                const posicoes = [];
                for (let i = 1; i <= this.qtdeColunas; i++) {
                    for (let j = 0; j < this.qtdeLinhas; j++) {
                        posicoes.push({id: 0, numero: i, letra: this.retornaColuna(j)});
                    }
                }

                this.model.posicoes = posicoes;
                this.geraColunasLinhas();
            },
            groupBy(list, keyGetter) {
                const map = new Map();
                list.forEach((item) => {
                    const key = keyGetter(item);
                    const collection = map.get(key);
                    if (!collection) {
                        map.set(key, [item]);
                    } else {
                        collection.push(item);
                    }
                });
                return map;
            }
        }
    }
</script>
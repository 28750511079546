<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns">
                <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Guia</label>
                    <b-input v-model="item.filtroGuia" placeholder="Id da guia" class="fix-position"></b-input>
                </div>                
                <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Geladeira</label>
                    <searchIdName
                        :ponto="false"
                        label=""
                        table="Geladeira"
                        :id.sync="item.geladeiraId"
                    ></searchIdName>
                </div>
            </div>
            <div class="columns">
                <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Container</label>
                    <searchIdName
                        :ponto="false"
                        label=""
                        table="Container"
                        :id.sync="item.containerId"
                    ></searchIdName>
                </div>                
                <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Paciente</label>
                    <searchIdName
                        :ponto="false"
                        label=""
                        table="Paciente"
                        :id.sync="item.pacienteId"
                    ></searchIdName>
                </div>
            </div>

            <div class="columns">
               <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Cód. da Amostra</label>
                    <b-input v-model="item.amostraCodigoDaAmostra" placeholder="Cód. da Amostra" class="fix-position"></b-input>
                </div>                
                <div class="column is-6">
                    <label class="label fix-margem-left-local-origem">Setor</label>
                    <searchIdName
                        :ponto="false"
                        label=""
                        table="Setor"
                        :id.sync="item.setorId"
                    ></searchIdName>
                </div>
            </div>            

            <div class="columns fix-margin">
                <periodo :dataInicial="dataInicial"  :dataFinal="dataFinal" @changed="dataAlterada"/>
            </div>
            <div class="columns fix-margin">
                <b-button type="is-primary" icon-left="filter" class="center" @click.prevent.stop="filterData">Filtrar</b-button>
            </div>
        </div>            

         <div class="box">
            <div class="columns">
                <div class="column is-12">
                <b-table v-if="model.lista.length" :data="model.lista"
                                :loading="loading"
                                paginated
                                striped
                                hoverable
                                @click="selectGuia"
                                backend-pagination
                                :perPage="numeroDeItens"
                                :total="total"
                                :currentPage="page"
                                @page-change="onPageChange"
                                aria-next-label="Próxima página"
                                aria-previous-label="Página anterior"
                                aria-page-label="Página"
                                aria-current-label="Página atual"
                                backend-sorting
                                :default-sort-direction="defaultOrdenar"
                                :default-sort="[ordenarPor, ordenar]"
                                sort-icon-size="is-small"
                                @sort="onSort">

                            <template slot-scope="props" @click="abrirCadastro(props.row.id)">
                                <b-table-column field="Amostra" label="Amostra">
                                    {{props.row.amostraCodigoDeBarras}}
                                </b-table-column>

                                <b-table-column field="Paciente" label="Paciente">
                                    {{ props.row.pacienteNome }}
                                </b-table-column>

                                <b-table-column field="Material" label="Material">
                                    {{ props.row.materialNome }}
                                </b-table-column> 

                                <b-table-column field="Conservante" label="Conservante">
                                    {{ props.row.conservanteNome }}
                                </b-table-column>  

                                <b-table-column field="Em" label="Em">
                                    {{ props.row.em | datahora }}
                                </b-table-column>  

                                <b-table-column field="Exames" label="Exames">
                                    <span class="tag" v-for="exame in props.row.apelidos" :key="exame" :class="{'is-success': exame.assinado, 'is-warning': exame.bloqueado, 'is-default': !exame.assinado && !exame.bloqueado}">
                                        {{exame.apelido}}
                                    </span>                    
                                </b-table-column>    


                                <b-table-column field="Multi" label="Geladeira/Container/Posiçao">
                                    {{props.row.geladeiraNome}}/{{props.row.containerNome}}/{{props.row.posicaoNumero}}{{props.row.posicaoLetra}}                    
                                </b-table-column>                                                            

                                <b-table-column field="" label="" v-if='isInRole("soroteca-remover")'>
                                    <div class="field">
                                        <b-switch size="is-small" v-model="props.row.checked" disabled="true" class="switch" :ref="'guia_'+props.row.id"></b-switch>
                                    </div>
                                </b-table-column>  

                            </template>
                        </b-table>                 
                </div>
            </div>
            <div class="columns"  v-if="!model.lista.length && dataLoaded">
                <div class="column is-12">
                    <p ><b>
                        Não existem registros para serem exibidos.
                    </b></p>
                </div>
            </div>
            <div class="columns">
                <div class="level-item center">
                    <p class="buttons">
                        <b-button type="is-success" icon-left="check-circle" @click="remover();" v-if="model.lista.length > 0">
                            Remover
                        </b-button>
                        <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                            Cancelar
                        </b-button>
                    </p>
                </div>
            </div>
        </div>          
    </section>
</template>

<style lang="scss" scoped>
.fix-margem-left-local-origem{
    margin-left: 0.5rem;
}

.is-default {
    background-color: #777;
    color: #fff;
}


.fix-position{
    margin-top: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}    

.center {
    margin: 0 auto;
    padding-top: 0 !important;
}

.sigla{
    border-radius: 1rem;
    border: 1px solid gray;
    text-align: center;
    width: 2rem ;
    height: 2rem;
    padding-top: 0.2rem;
    font-weight: bolder;
}

.bg-header {
    background-color: #d9edf7;
    height: 2rem;
}

.txt-obs {
    font-weight: bold;
}

td{
    cursor: pointer;
}

.center {
    margin: 0 auto;
}

.align-right{
    text-align: right;
}

.fix-label {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    font-weight: bold;
}

.fix-margin{
    padding: 0.5rem;
}

.panel-inline{
    display: inline-flex;
}
</style>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodo from '@/components/periodo.vue'
    import moment from 'moment';

    export default {
        data() {
            return {
                model: {
                    lista: []
                },
                item: {
                    geladeiraId: null,
                    amostraCodigoDaAmostra: null,
                    containerId: null,
                    setorId: null,
                    filtroGuia: null,
                    pacienteId: null
                }, 
                funcionarios: [],               
                total: 0,
                perPage: 50,
                numeroDeItens: 50,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Nome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                selecionado: null,                
                id: null,
                dataLoaded: false,
                dataInicial: null,
                dataFinal: null
            }
        },
        components: {
            titlebar,
            searchIdName,
            periodo
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    'Soroteca',
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        filters: {
            datahora: (valor) => {
                if (!valor) return null;
                
                return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {	          
            getStatusHtml(row){
                const style={'background-color': !row.type && row.statusColor ? row.statusColor : '', 'border': row.faturado ? "{'border': '3px double silver'}" : ''};
                if (row.foreColor){
                    style.color = 'white';
                }
                return style;
            },
            selectGuia(row){
                row.checked = !row.checked;
                const ref = this.$refs['guia_'+ row.id];
                ref.value = row.checked;

                this.$forceUpdate();
            },
            filterData() {
                if (this.dataInicial){
                    this.item.dataInicial = moment(this.dataInicial).format();
                }
                if (this.dataFinal){
                    this.item.dataFinal = moment(this.dataFinal).format();
                }
                this.item.page = this.page;

                this.$router.replace({query: {...this.item}});

                const params = [];
                Object.keys(this.$route.query).forEach(key => {
                    const value = this.$route.query[key];
                    if (value){
                        params.push(`${key}=${value}`);
                    }else{
                        this.item[key] = null;
                    }
                });

                //int? page, int? filtroGuia, int? GeladeiraId, int? ContainerId, int? PacienteId, int? SetorId, string amostraCodigoDaAmostra, DateTime? dataInicial, DateTime? dataFinal
                this.loading = true
                try {
                    this.$http.get(`/api/analitico/soroteca?${params.join('&')}`).then(({data}) => {
                        this.model = data;
                        this.model.lista = this.model.lista?.map(i => {
                            i.checked = false;
                            i.id = Math.random();
                            return i;
                        })
                        if (this.model.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.filterData()                        
                        }
                        this.total = this.model?.pageCount * this.numeroDeItens;
                        this.loading = false;
                        this.page = this.model.page;
                        this.$router.replace({query: {...this.$route.query, page: this.page }});  
                        this.dataLoaded = true;                 
                    }).catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
                }finally{
                    this.dataLoaded = true;
                }
                
                
            },            
            onPageChange(page) {
                this.page = page;
                this.$router.replace({query: {...this.$route.query, page: this.page}});                   
                this.filterData();
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.filterData();
            },
            abrirCadastro() {
                this.$router.push({ path: `/financeiro/bandeira/${this.selecionado.id}` })
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/financeiro/bandeira/${this.id}` })
            },
            dataAlterada({dataInicial, dataFinal}){
                this.dataInicial = dataInicial;
                this.dataFinal = dataFinal;
            },
            remover(){
                const guias = this.model?.lista?.filter(i => i.checked).map(i => {
                    i.confirmar = true;
                    return i;
                })
                if (!guias.length){
                      this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Ao menos um item precisa ser selecionado',
                            type: 'is-danger',
                            queue: false
                        })

                        return;
                }
                         
                this.$http.post(`/api/analitico/sorotecaRemover`,guias).then((response) => {
                    if (response.status === 200){                    
                        this.$router.push('/analise');
                    }
                });
            }
        },
        async mounted() {
            this.$nextTick(() => {
                if (this.$route?.query){
                    // if (this.$route.query.dataInicial){
                    //     this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm:ss').toDate();
                    // }
                    // if (this.$route.query.dataFinal){                        
                    //     this.dataInicial = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm:ss').toDate();
                    // }
                    this.item = {};
                    Object.keys(this.$route.query).forEach(key => {
                        let value = this.$route.query[key];
                        if (value && value != ''){
                            if (!isNaN(value)){
                                value = parseInt(value);
                            }
                            if (!key.startsWith('data')){
                                this.item[key] = value;
                            }
                        }
                    })
                }  

                this.filterData();
            })
        }
    }
</script>
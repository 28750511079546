<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Alterar data de solicitação</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-datetimepicker rounded
                                  placeholder="Selecione a data e hora"
                                  icon="calendar-today"
                                  v-model="novaDataDeSolicitacao"
                                  inline
                                  horizontal-time-picker>
                </b-datetimepicker>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      @click="alterarDataDeSolicitacao">Confirmar</b-button>
            <b-button @click="$emit('close')">Cancelar</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            selecionado: Array
        },
        data() {
            return {
                novaDataDeSolicitacao: new Date()
            }
        },
        methods: {
            alterarDataDeSolicitacao() {
                this.selecionado.forEach(x => {
                    x.dataDeSolicitacao = this.novaDataDeSolicitacao
                })
                this.$emit('close')
            }
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-12"> 
                            <div class="field has-text-centered">
                                <b-switch v-model="parcelasVencidas">
                                    Somente Parcelas Vencidas
                                </b-switch>
                            </div>
                            <b-table v-if="lista.length != 0" :data="lista" 
                                :default-sort-direction="defaultOrdenar"
                                :default-sort="[ordenarPor, ordenar]"
                                sort-icon-size="is-small"
                                @sort="onSort">                               
                                <template slot-scope="props">

                                    <b-table-column field="receberId" label="Código" sortable>
                                        <router-link :to="{ name: 'contas-receber', params: { id: props.row.receberId }}">
                                            {{ props.row.receberParcelaId }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column field="dataDeVencimento" label="Data de Vencimento" sortable>
                                        {{ $moment(props.row.dataDeVencimento).format("DD/MM/YYYY") }}
                                    </b-table-column>

                                    <b-table-column field="pacienteNome" label="Paciente" sortable>
                                        <router-link :to="{ name: 'paciente', params: { id: props.row.pacienteId }}">
                                            {{ props.row.pacienteNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column field="convenioNome" label="Convênio" sortable>
                                        <router-link :to="{ name: 'convenio', params: { id: props.row.convenioId }}">
                                            {{ props.row.convenioNome }}
                                        </router-link>
                                    </b-table-column>

                                    <b-table-column field="valor" label="Valor (R$)" sortable>
                                        {{ formatPrice(props.row.valor) }}
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>     
                    </div>  
                    <div class="columns">   
                        <div class="column is-12">                 
                            <article class="tile is-child box">           
                                <div class="columns">  
                                    <b-field label="Total de Paciente">
                                        <b-input type="text" class="column" v-model="listaCompleta.filter(item => item.receberId != null).length" readonly></b-input>
                                    </b-field>
                                    <b-field label="Total (R$)">
                                        <b-input type="text" class="column" v-model="totalValor" readonly></b-input>
                                    </b-field>
                                </div>     
                            </article>
                        </div>     
                    </div>  
                </article>                
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                lista: [],
                listaCompleta: [],
                ordenarPor: 'pacienteNome',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                loading: false,
                totalValor: 0,
                parcelasVencidas: false
            }
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Relação de Débitos'
                ]
            }
        },
        mounted() {
            this.loadAsyncData()
        },
        watch: {
            parcelasVencidas(){
                if(this.parcelasVencidas){
                    this.lista = this.listaCompleta.filter(item => item.dataDeVencimento < this.$moment(new Date()).format("YYYY-MM-DD"));
                }
                else {
                    this.lista = this.listaCompleta;
                }
            }
        },
        methods: {
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            loadAsyncData() {
                this.loading = true

                this.$http.get(`/api/financeiro/relacaodedebitos?sintetizado=${(this.$route.params.sintetizado != null ? this.$route.params.sintetizado : false)}`)
                    .then(({ data }) => {
                        this.lista = data;
                        this.listaCompleta = data;

                        for (let i = 0; i < this.lista.length; i++) {
                            this.totalValor += this.lista[i].valor;
                        }
                        this.totalValor = this.formatPrice(this.totalValor);


                        this.loading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.loading = false
                        throw error
                    })
            },
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class="box">                
            <b-tabs position="is-right" type="is-boxed" v-model="activeItemTab">
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab == 0"> Filtro </span>
                    </template>
                    <div class="columns is-multiline">            
                        <div class="column is-6">
                            <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" label="Unidade"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="local.id" :item.sync="local" table="Local" label="Local de Origem"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" label="Convênio"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="tipoConvenio.id" :item.sync="tipoConvenio" table="TipoConvenio" label="Tipo de Convênio"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="setor.id" :item.sync="setor" table="Setor" label="Setor"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="departamento.id" :item.sync="departamento" table="Departamento" label="Departamento"/>
                        </div>
                        <div class="column is-6">
                            <div class="panel">
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">Emissão</a>
                                    <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">Solicitação</a>
                                    <a :class="{ 'is-active': dataFiltro == 'alta' }" @click="dataFiltro = 'alta'">Alta</a>
                                    <a :class="{ 'is-active': dataFiltro == 'digitacao' }" @click="dataFiltro = 'digitacao'">Digitação</a>
                                    <a :class="{ 'is-active': dataFiltro == 'impressao' }" @click="dataFiltro = 'impressao'">Impressão</a>
                                    <a :class="{ 'is-active': dataFiltro == 'coleta' }" @click="dataFiltro = 'coleta'">Coleta</a>
                                    <a :class="{ 'is-active': dataFiltro == 'assinatura' }" @click="dataFiltro = 'assinatura'">Assinatura</a>
                                </p>
                                <div class="panel-block">
                                    <periodoHora :dataInicial.sync="datas[0]"
                                        :dataFinal.sync="datas[1]"/>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6">
                            <b-field>
                                <b-checkbox v-model="incluirConvenioParticular">Incluir convênios particulares</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="somenteGuiaConferida">Guia conferida</b-checkbox>
                            </b-field>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab == 0"> Selecionados </span>
                    </template>
                    <div class="columns is-multiline">   
                        <div class="column is-12">                       
                            <b-table v-if="guias.length > 0" :data="guias"
                                    :loading="loading"
                                    striped
                                    hoverable
                                    :checked-rows.sync="checkedRows"
                                    checkable
                                    checkbox-position="left"
                                    sort-icon-size="is-small">

                                <template slot-scope="props">
                                    <b-table-column field="Guia" label="Guia">                                    
                                        {{ props.row.id }}
                                    </b-table-column>

                                    <b-table-column v-if="config.faturarBuscandoReferencia" field="Referencia" label="Referencia">
                                        {{ props.row.referencia }}
                                    </b-table-column>

                                    <b-table-column field="Paciente" label="Paciente">
                                        {{ props.row.pacienteNome }}
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <br>
                    <div class="columns is-multiline">  
                        <div class="box">
                            <article class="level">
                                <div class="level-left">
                                    <div class="is-light">
                                        <form @submit.prevent="incluirGuia" method="get">
                                            <label><strong>Guia</strong></label>
                                            <div class="field has-addons">
                                                <div class="control">
                                                    <b-input v-model="guia" ref="guiaFaturar"></b-input>
                                                </div>
                                                <div class="control">
                                                    <b-button native-type="submit">
                                                        Incluir
                                                    </b-button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item v-if="config.faturaAgendamento && isInRole('fatura-agendamento')">
                    <template slot="header">
                        <span @click="activeItemTab == 0"> Agendamentos </span>
                    </template>
                    <div class="columns is-multiline">  
                        <agendamentos></agendamentos>
                    </div>
                </b-tab-item>
            </b-tabs>    
            <div class="columns is-multiline">     
                <div class="column tile is-12">
                    <div class="column is-2">
                        <label class="label">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="guia">
                            Guia
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="referencia">
                            Referência
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="cliente">
                            Paciente
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataEmissao">
                            Data de emissão
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataSolicitacao">
                            Data de solicitação
                        </b-radio>
                    </div>
                </div>
            </div>
            <hr>        
            <div class="columns is-multiline">  
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="dataNascimento">Data de Nascimento</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="numeroConselho">Número do Conselho</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="dataSolicitacao">Data de Solicitação</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="CNS">CNS em vez de Inscrição</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="materialEntregue">Somente com Material Entregue</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteResultadoAssinado">Somente com Resultado Assinado</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="agrupaSetor">Agrupa Relatório por Setor</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteFaturados">Somente já faturados</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="estudoClinico">Estudo Clínico</b-checkbox>
                    </b-field>
                </div>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                native-type="button"
                                :loading="loading"
                                @click="enviar()">
                        Criar documento
                    </b-button>
                    <b-button type="is-primary"
                                native-type="button"
                                :loading="loading"
                                @click="gerarPdf()">
                        Gerar PDF
                    </b-button>
                    <b-button type="is-warning"
                                native-type="button"
                                :loading="loading"
                                @click="gerarXls()">
                        Gerar XLS
                    </b-button>
                    <b-button type="is-info" v-if="config.faturaAgendamento && isInRole('fatura-agendamento')"
                                native-type="button"
                                :loading="loading"
                                @click="agendar()">
                        Criar agendamento
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters, mapState  } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import moment from 'moment';
    import periodoHora from '@/components/periodoHora.vue'
    import agendamentos from '@/components/financeiro/agendamentos.vue'

    export default {
        data(){
            return {
                activeItemTab: 0,
                unidade: {
                    id: null,
                    nome: null
                },
                local: {
                    id: null,
                    nome: null
                },
                convenio: {
                    id: null,
                    nome: null
                },
                tipoConvenio: {
                    id: null,
                    nome: null
                },
                setor: {
                    id: null,
                    nome: null
                },
                departamento: {
                    id: null,
                    nome: null
                },
                dataFiltro: 'emissao',
                incluirConvenioParticular: false,
                somenteGuiaConferida: false,
                guias: [],
                checkedRows: [],
                guia: "",
                loading: false,
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],
                ordena: 'guia',
                dataNascimento: true,
                numeroConselho: true,
                dataSolicitacao: true,
                CNS: false,
                materialEntregue: false,
                somenteResultadoAssinado: false,
                agrupaSetor: false,
                somenteFaturados: false,
                estudoClinico: false
            }
        },
        components: {
            titlebar,
            searchIdName,
            periodoHora,
            agendamentos
        },        
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Contas a receber',
                    'Faturar'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config'
            ])
        },        
        methods:{            
            incluirGuia(){
                this.loading = true;
                
                let url = '/api/Financeiro/BuscarGuiaFaturar?id=' + this.guia;

                if(this.config.faturarBuscandoReferencia){
                    url = '/api/Financeiro/BuscarGuiaFaturar?referencia=' + this.guia + 
                        '&dataInicial=' + this.$moment(this.datas[0]).format() +
                        '&datafinal=' + this.$moment(this.datas[1]).format()
                }

                this.$http.get(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {
                                this.guias.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }
                        }
                        this.loading = false;
                    });

                this.$refs.guiaFaturar.focus();
            },
            enviar(){
                this.loading = true;
                const faturar = this.parametros();

                this.$http.post('/api/financeiro/ReceberFaturar', faturar)
                    .then(res => res.data)
                    .then(data => {
                        this.$router.push({ name: 'lista-contas-receber' });
                        
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    }); 

                this.limparLista();
            },
            agendar(){
                this.loading = true;
                const faturar = this.parametros();

                this.$http.post('/api/financeiro/ReceberFaturarAgendamento', faturar)
                    .then(res => res.data)
                    .then(data => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Agendamento realizado.',
                            type: 'is-info',
                            queue: false
                        })
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    }); 

                this.limparLista();
            },
            parametros(){
                const faturar = {
                    convenioId: this.convenio.id, 
                    dataInicial: this.datas[0],
                    dataFinal: this.datas[1], 
                    somenteFaturados: this.somenteFaturados, 
                    somenteResultadoAssinado: this.somenteResultadoAssinado, 
                    estudoClinico: this.estudoClinico, 
                    dataNascimento: this.dataNascimento,
                    numeroConselho: this.numeroConselho,
                    CNS: this.CNS,
                    dataSolicitacao: this.dataSolicitacao,
                    materialEntregue: this.materialEntregue,
                    agrupaSetor: this.agrupaSetor,
                    ordenaPor: this.ordena,
                    filtraPor: this.dataFiltro,
                    unidadeId: this.unidade.id,
                    localDeOrigemId: this.local.id,
                    setorId: this.setor.id,
                    departamentoId: this.departamento.id,
                    tipoConvenioId: this.tipoConvenio.id,
                    guiaId: this.checkedRows.map(item => item.id),
                    incluirConvenioParticular: this.incluirConvenioParticular,
                    somenteGuiaConferida: this.somenteGuiaConferida,
                };

                return faturar;
            },
            parametrosArquivo(){

                const params = [
                    `somenteFaturados=${this.somenteFaturados}`,
                    `somenteResultadoAssinado=${this.somenteResultadoAssinado}`,
                    `estudoClinico=${this.estudoClinico}`,
                    `dataNascimento=${this.dataNascimento}`,
                    `numeroConselho=${this.numeroConselho}`,
                    `CNS=${this.CNS}`,
                    `dataSolicitacao=${this.dataSolicitacao}`,
                    `materialEntregue=${this.materialEntregue}`,
                    `agrupaSetor=${this.agrupaSetor}`,
                    `ordenaPor=${this.ordena}`,
                    `filtraPor=${this.dataFiltro}`,
                    `incluirConvenioParticular=${this.incluirConvenioParticular}`,
                    `somenteGuiaConferida=${this.somenteGuiaConferida}`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ];

                if (this.checkedRows != null && this.checkedRows.length > 0){
                    for (const i in this.checkedRows) {
                        params.push(`guiaId[${i}]=${this.checkedRows[i].id}`);
                    }
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }
                if(this.unidade != null && this.unidade.id != null){
                    params.push(`unidadeId=${this.unidade.id}`);
                }
                if(this.local != null && this.local.id != null){
                    params.push(`localDeOrigemId=${this.local.id}`);
                }
                if(this.setor != null && this.setor.id != null){
                    params.push(`setorId=${this.setor.id}`);
                }
                if(this.departamento != null && this.departamento.id != null){
                    params.push(`departamentoId=${this.departamento.id}`);
                }
                if(this.tipoConvenio != null && this.tipoConvenio.id != null){
                    params.push(`tipoConvenioId=${this.tipoConvenio.id}`);
                }

                return params;
            },
            gerarPdf(){
                const params = this.parametrosArquivo();
                params.push(`formato=pdf`);

                window.open(`/api/financeiro/FaturamentoConvenio?${params.join('&')}`,'_blank');

                this.limparLista();
            },
            gerarXls(){
                const params = this.parametrosArquivo();
                params.push(`formato=xls`);

                window.open(`/api/financeiro/FaturamentoConvenio?${params.join('&')}`,'_blank');

                this.limparLista();
            },
            limparLista(){                
                this.guias = [];
                this.checkedRows = [];
            }
        }
    }
</script>
<template>
    <section>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 0"
                    @open="isOpen = 0;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Pessoas
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('paciente-alterar') || isInRole('paciente-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Pacientes
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'pacientes', query: { procurar: procurar.paciente }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                        v-model="procurar.paciente"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'pacientes' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('paciente-alterar')" :to="{ name: 'paciente' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('especie-alterar') || isInRole('especie-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Espécies
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'especies', query: { procurar: procurar.especie }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                        v-model="procurar.especie"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'especies' }" class="button is-light is-fullwidth">
                                    Listar todas
                                </router-link>
                                <router-link v-if="isInRole('especie-alterar')" :to="{ name: 'especie' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('raca-alterar') || isInRole('raca-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Raças
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'racas', query: { procurar: procurar.raca }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                        v-model="procurar.raca"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'racas' }" class="button is-light is-fullwidth">
                                    Listar todas
                                </router-link>
                                <router-link v-if="isInRole('raca-alterar')" :to="{ name: 'raca' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('medico-alterar') || isInRole('medico-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Médicos
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'medicos', query: { procurar: procurar.medico }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.medico"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'medicos' }" class="button is-light is-fullwidth">
                                    Listar todos
                                  </router-link>
                                <router-link v-if="isInRole('medico-alterar')" :to="{ name: 'medico' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-1">
                                Funcionários
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'funcionarios', query: { procurar: procurar.funcionario }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.funcionario"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'funcionarios' }" class="button is-light is-fullwidth">
                                    Listar todos
                                  </router-link>
                                <router-link v-if="isInRole('Administrador')" :to="{ name: 'funcionario' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-2">
                                Níveis de acesso
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'niveisdeacesso', query: { procurar: procurar.niveldeacesso }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.niveldeacesso"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'niveisdeacesso' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link :to="{ name: 'niveldeacesso' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 1"
                    @open="isOpen = 1;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Empresas
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('convenio-alterar') || isInRole('convenio-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Convênios
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'convenios', query: { procurar: procurar.convenio }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.convenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'convenios' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('convenio-alterar') && 
                                integradores.filter(x => x.ativo && x.versao == 30).length == 0" 
                                    :to="{ name: 'convenio' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('tipoConvenio-alterar') || isInRole('tipoConvenio-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Tipos de convênios
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeconvenios', query: { procurar: procurar.tipoConvenio }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.tipoConvenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeconvenios' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('tipoConvenio-alterar')" :to="{ name: 'tipodeconvenio' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('fornecedor-alterar') || isInRole('fornecedor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Fornecedores
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'fornecedores', query: { procurar: procurar.fornecedor }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.fornecedor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>                           
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'fornecedores' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('fornecedor-alterar')" :to="{ name: 'fornecedor' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 2"
                    @open="isOpen = 2; salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Exames
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                         <div v-if="isInRole('exame-alterar') || isInRole('exame-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Exames
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'exames', query: { procurar: procurar.exame }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.exame"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'exames' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('exame-alterar')" :to="{ name: 'exame' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column">
                        <div v-if="isInRole('material-alterar') || isInRole('material-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Materiais
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'materiais', query: { procurar: procurar.material }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.material"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'materiais' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('material-alterar')" :to="{ name: 'material' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('conservante-alterar') || isInRole('conservante-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Conservantes
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'conservantes', query: { procurar: procurar.conservante }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.conservante"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'conservantes' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('conservante-alterar')" :to="{ name: 'conservante' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                     <div class="column">
                        <div v-if="isInRole('mascaraAbreviatura-alterar') || isInRole('mascaraAbreviatura-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Abreviaturas
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'abreviaturas', query: { procurar: procurar.mascaraAbreviatura }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.mascaraAbreviatura"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form> 
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'abreviaturas' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('mascaraAbreviatura-alterar')" :to="{ name: 'abreviatura' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('exameGrupo-alterar') || isInRole('exameGrupo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Grupos de exames
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'grupoexames', query: { procurar: procurar.exameGrupo }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.exameGrupo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'grupoexames' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('exameGrupo-alterar')" :to="{ name: 'grupoexame' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column">
                        <div v-if="isInRole('exameCodigoTipo-alterar') || isInRole('exameCodigoTipo-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Tipo do código de exame
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'examescodigostipos', query: { procurar: procurar.exameCodigoTipo }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.exameCodigoTipo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'examescodigostipos' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('exameCodigoTipo-alterar')" :to="{ name: 'examecodigotipo' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">                        
                        <div v-if="isInRole('setor-alterar') || isInRole('setor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Setores
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'setores', query: { procurar: procurar.setor }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.setor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'setores' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('setor-alterar')" :to="{ name: 'setor' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('departamento-alterar') || isInRole('departamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Departamentos
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'departamentos', query: { procurar: procurar.departamento }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.departamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'departamentos' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('departamento-alterar')" :to="{ name: 'departamento' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('tabelapreço-alterar') || isInRole('tabelapreço-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Tabelas de preços
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tabelasprecos', query: { procurar: procurar.tabelapreco }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.tabelapreco"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tabelasprecos' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('tabelapreço-alterar')" :to="{ name: 'novatabelapreco' }"  class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('valorescbphm-alterar') || isInRole('valorescbphm-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Valores CBHPM
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'valorescbhpm', query: { procurar: procurar.valorescbphm }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.valorescbphm"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'valorescbhpm' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('valorescbphm-alterar')" :to="{ name: 'valorcbhpm' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                    </div>                        
                    <div class="column">
                        <div v-if="isInRole('motivo-alterar') || isInRole('motivo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                Motivos
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'motivos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.motivo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'motivos' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('motivo-alterar')" :to="{ name: 'motivo' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                    <div class="column">
                        <div v-if="isInRole('liberacaoAutomatica-alterar') || isInRole('liberacaoAutomatica-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Liberação Automática
                            </p>                            
                            <form @submit.stop.prevent="$router.push({ name: 'liberacoesautomatica', query: { procurar: procurar.liberacaoAutomatica }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.liberacaoAutomatica"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'liberacoesautomatica' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('liberacaoAutomatica-alterar')" :to="{ name: 'liberacaoautomaticaconfig' }" class="button is-primary is-fullwidth">
                                    Configurar
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 3"
                    @open="isOpen = 3;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Outros
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('unidade-alterar') || isInRole('unidade-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Unidades
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'unidades', query: { procurar: procurar.unidade }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.unidade"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'unidades' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('unidade-alterar')" :to="{ name: 'unidade' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('guiaLocal-alterar') || isInRole('guiaLocal-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            Locais
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'locais', query: { procurar: procurar.guiaLocal }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.guiaLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'locais' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('guiaLocal-alterar')" :to="{ name: 'local' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('tipoDeLocal-alterar') || isInRole('tipoDeLocal-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Tipos de locais
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'tiposdelocais', query: { procurar: procurar.tipoDeLocal }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.tipoDeLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'tiposdelocais' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('tipoDeLocal-alterar')" :to="{ name: 'tipodelocal' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                 <div class="column">
                        <div v-if="isInRole('tipos-agendamento-alterar') || isInRole('tipos-agendamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                Tipos de agendamento
                            </p>                                                
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeagendamento', query: { procurar: procurar.tiposdeAgendamento }})" method="get" class="panel-block">
                                <b-input placeholder="Procurar"
                                         v-model="procurar.tiposdeAgendamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeagendamento' }" class="button is-light is-fullwidth">
                                    Listar todos
                                </router-link>
                                <router-link v-if="isInRole('tipos-agendamento-alterar')" :to="{ name: 'tipoagendamento' }" class="button is-link is-fullwidth">
                                    Incluir
                                </router-link>
                            </div>
                        </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('pesquisa-alterar') || isInRole('pesquisa-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            Pesquisas
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'pesquisas', query: { procurar: procurar.pesquisa }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.pesquisa"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'pesquisas' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('pesquisa-alterar')" :to="{ name: 'pesquisa' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('feriado-alterar') || isInRole('feriado-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Feriados
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'feriados', query: { procurar: procurar.feriado }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.feriado"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'feriados' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('feriado-alterar')" :to="{ name: 'feriado' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('condicaopagamento-alterar') || isInRole('condicaopagamento-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            Condições de pagamento
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'condicoespagamentos', query: { procurar: procurar.condicaopagamento }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.condicaopagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'condicoespagamentos' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('condicaopagamento-alterar')" :to="{ name: 'condicaopagamento' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('especie-pagamento-alterar') || isInRole('especie-pagamento-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Espécie de pagamento
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'especiespagamentos', query: { procurar: procurar.especiepagamento }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.especiepagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'especiespagamentos' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('especie-pagamento-alterar')" :to="{ name: 'especiepagamento' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('sorotecageladeira-alterar') || isInRole('sorotecageladeira-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Soroteca - Geladeiras
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecageladeiras', query: { procurar: procurar.sorotecageladeira }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.sorotecageladeira"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecageladeiras' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('sorotecageladeira-alterar')" :to="{ name: 'sorotecageladeira' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('sorotecacontainer-alterar') || isInRole('sorotecacontainer-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            Soroteca - Containers
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecascontainers', query: { procurar: procurar.sorotecacontainer }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.sorotecacontainer"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecascontainers' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('sorotecacontainer-alterar')" :to="{ name: 'sorotecacontainer' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('configTISS-alterar') || isInRole('configTISS-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            Configuração TISS
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'configuracoestiss', query: { procurar: procurar.configTISS }})" method="get" class="panel-block">
                            <b-input placeholder="Procurar"
                                         v-model="procurar.configTISS"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'configuracoestiss' }" class="button is-light is-fullwidth">
                                Listar todos
                            </router-link>
                            <router-link v-if="isInRole('configTISS-alterar')" :to="{ name: 'configuracaotiss' }" class="button is-link is-fullwidth">
                                Incluir
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('cron-alterar')" class="panel">
                        <p class="panel-heading background-2">
                            Configurações de agendamentos
                        </p>
                        <p class="panel-block">
                            <small>Serviços executados periodicamente</small>                            
                        </p>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'agendamentotarefa' }" class="button is-light is-fullwidth">
                                Alterar
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </b-collapse>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {}
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
        }
    }
</script>
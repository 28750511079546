<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        
        <b-message
            v-for="(erro, index) in erros"
            v-bind:key="index"
            title="Erro"
            type="is-danger"
            aria-close-label="Fechar mensagem">
            {{ erro }}
        </b-message>
        
        <div class='box'>
            <div class="columns">
                <div class="column">
                    <label><strong>Setor</strong></label>
                    <b-taginput :data="setores"
                                autocomplete
                                allow-new
                                v-model="setoresEscolhidos"
                                placeholder="Inclua um setor"
                                @typing="getSetores">
                    </b-taginput>
                </div>
                <div class="column">
                    <label><strong>Convenio</strong></label>
                    <b-taginput :data="convenios"
                                autocomplete
                                allow-new
                                v-model="conveniosEscolhidos"
                                placeholder="Inclua um convênio"
                                @typing="getConvenios">
                    </b-taginput>
                </div>
            </div>
        </div>


        <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                checkbox-position="left"
                sort-icon-size="is-small"
                @sort="onSort">

            <template slot-scope="props">
                <b-table-column field="Contador" label="">
                    <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                </b-table-column>

                <b-table-column field="amostraCodigoDeBarras" label="Amostra" sortable>
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column field="guia" label="Guia" sortable>
                    {{ props.row.guia }}
                </b-table-column>

                <b-table-column field="pacienteNome" label="Paciente" sortable>
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column field="materialNome" label="Material" sortable>
                    {{ props.row.materialNome }}
                </b-table-column>

                <b-table-column field="conservanteNome" label="Conservante" sortable>
                    {{ props.row.conservanteNome }}
                </b-table-column>

                <b-table-column field="setorNome" label="Setor" sortable>
                    {{ props.row.setorNome }}
                        <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                </b-table-column>

                <b-table-column field="Exames" label="Exames">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column field="Acondicionamento" label="">
                    <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" title="Acondicionamento: Congelada">C</span>
                    <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" title="Acondicionamento: Refrigerada">R</span>
                    <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" title="Acondicionamento: Ambiente">A</span>
                </b-table-column>

                <b-table-column field="Incidencia" label="Incidência">
                    <span class="tag pull-right is-success is-light " v-if="props.row.incidencia == 'A'" title="Aceitar amostra">A</span>
                    <span class="tag pull-right is-warning is-light " v-if="props.row.incidencia == 'R'" title="Aceitar amostra (Com restrição)">R</span>
                    <span class="tag pull-right is-danger is-light " v-if="props.row.incidencia == 'N'" title="Não aceitar (Rejeição)">N</span>
                </b-table-column>
            </template>
        </b-table>
        <br>
        <div class="box">
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <label><strong>Código de Barras</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <b-input v-model="amostraCodigoDeBarras" ref="amostraCodigoDeBarras" id="amostraCodigoDeBarras"></b-input>
                                </div>
                                <div class="control">
                                    <b-button native-type="submit">
                                        Incluir
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="level-item">
                    <article class="level">
                        <div class="level-item">
                            <div class="is-light">
                                <label><strong>Código da Guia</strong></label>
                                <div class="field has-addons">
                                    <div class="control">                                        
                                        <b-input type="text"  ref="guiaId" v-model="guiaId" @keyup.native.enter="incluirGuia(resultEdit)"></b-input>
                                    </div>
                                    <div class="control">
                                        <a class="button" @click="incluirGuia();">
                                        Incluir
                                        </a>
                                    </div>
                                    <div class="control">
                                        <a class="button is-info" @click="incluirPendencias();">
                                        Pendências
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="level-right">
                    <div class="is-light">
                        <label><strong>Lote de Amostras</strong></label>
                        <div class="field has-addons">
                            <div class="control">
                                <input type="text" v-model="amostraLoteId" class="input" ref="amostraLoteId" id="amostraLoteId" />
                            </div>
                            <div class="control">
                                <a class="button" @click="incluirLote();">
                                Incluir
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <div class='box'>
            <div class="columns">
                <div class="column is-one-third">
                    <div class="columns">
                        <div class="column is-6">
                            Funcionário responsável:
                            <b-select v-model="funcionarioId">
                                <template v-for="(item, index) in this.funcionariosAtivos">
                                    <option v-if="funcionarioId == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3" v-if="this.funcionariosAtivos.length > 0">
                            <br>
                            <div class="control">
                                <input type="text" placeholder="Identificador" v-model="funcionarioIdentificador" class="input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <label><strong>Temperatura (C°)</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column">
                    <label><strong>Umidade (%)</strong></label>
                    <input type="number" v-model="umidade" class="input" />
                </div>
                <div class="column">
                    <label><strong>Latitude</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column">
                    <label><strong>Longitude</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
                <div class="column">
                    <br>
                    <b-switch v-model="bancada">
                        Emitir Bancada
                    </b-switch>
                </div>
                <div class="column">
                    <label class="label">Enviar para Integrador</label>
                    <b-select placeholder="Integrador" v-model="integradorId">
                        <option value=""></option>
                        <option v-for="option in integradores"
                                :value="option.id"
                                :key="option.id">
                            {{ option.nome }}
                        </option>
                    </b-select>
                </div>
            </div>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-primary" @click="imprimir();">
                        Imprimir
                    </b-button>
                    <b-button type="is-success" :loading="loading" icon-left="check-circle" @click="enviarCheckpoint();">
                        Enviar
                    </b-button>
                    <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapGetters, mapState } from 'vuex'
    import modalRecebimentoIncidencia from '@/components/atendimento/modalRecebimentoIncidencia.vue'

    export default {
        data() {
            return {
                funcionarioId: null,
                funcionarioIdentificador: "",
                funcionariosAtivos: [],
                setoresEscolhidos: [],
                setores: [],
                conveniosEscolhidos: [],
                convenios: [],
                amostras: [],
                amostraCodigoDeBarras: "",
                amostraLoteId: "",
                guiaId: "",
                temperatura: "",
                umidade: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                bancada: localStorage.emitirBancadaCheckpoint ?? false,
                equipmentId: this.$route.params?.equipmentid ?? null,
                integradorId: null,
                ordenarPor: null,
                ordenar: 'asc',
                opcoesBancada: null
            }
        },
        watch: {
            funcionarioIdentificador: function(val){
                if(this.funcionariosAtivos.filter(func => func.identificador == val).length > 0){
                    this.funcionarioId = this.funcionariosAtivos.filter(func => func.identificador == val)[0].id
                }
            },
            amostraCodigoDeBarras: function (val) {
                //verifica se existe na lista
                if(this.amostras.filter(am => am.amostraCodigoDeBarras == val).length > 0){                    
                    this.amostras.filter(am => am.amostraCodigoDeBarras == val).forEach(item => {
                            item.amostraBipada = true;
                            this.checkedRows.push(item);
                        });

                    if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                        this.$buefy.dialog.alert({
                            title: 'Amostra já selecionada',
                            message: 'A amostra ' + this.amostraCodigoDeBarras + ' já incluída na lista.',
                            type: 'is-warning',
                            hasIcon: true,
                            onConfirm: () => this.$refs.amostraCodigoDeBarras.focus()
                        });
                    }

                    this.amostraCodigoDeBarras = null;

                }
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Lote de Amostras',
                    'Checkpoint'
                ]
            },
            ...mapState([
                'integradores',
                'config'
            ])
        },
        mounted(){
            this.$http.get('/api/search/FuncionariosAtivos')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.funcionariosAtivos = data;
                    }
                });
                
            this.$refs.amostraCodigoDeBarras.focus();
        },
        created() {
        this.$getLocation({})
            .then(coordinates => {
                this.latitude = coordinates.lat
                this.longitude = coordinates.lng
            });
        },
        methods:{
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            incluirAmostra(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluir?codigoDeBarras=' + this.amostraCodigoDeBarras)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Setor.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Convênio.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            
                            if(data[i].temIncidencia){
                                this.incidencia(data[i]);
                            }
                            else {
                                this.amostras.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }
                        }
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.',
                            type: 'is-warning',
                            queue: false
                        });
                    }

                    this.$refs.amostraCodigoDeBarras.focus();
                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

                this.amostraCodigoDeBarras = "";
            },
            incluirLote(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirLote?amostraLoteId=' + this.amostraLoteId)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Setor.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Convênio.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(data[i].temIncidencia){
                                this.incidencia(data[i]);
                            }
                            else {
                                this.amostras.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }                            
                        }
                    }

                    this.$refs.amostraLoteId.focus();
                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

                this.amostraLoteId = "";
            },
            incluirGuia(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirGuia?guiaId=' + this.guiaId)
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Setor.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Convênio.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(data[i].temIncidencia){
                                this.incidencia(data[i]);
                            }
                            else {
                                this.amostras.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }                            

                        }
                    }

                    this.$refs.guiaId.focus();
                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

                this.guiaId = "";
            },
            incluirPendencias(){
                this.erros = [];
                this.loading = true;

                this.$http.get('/api/atendimento/AmostraLoteCheckpointIncluirPendenciasDeBancada')
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            if(this.setoresEscolhidos != null && this.setoresEscolhidos.length > 0){
                                if(this.setoresEscolhidos.filter(item => item == data[i].setorNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Setor.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            if(this.conveniosEscolhidos != null && this.conveniosEscolhidos.length > 0){
                                if(this.conveniosEscolhidos.filter(item => item == data[i].convenioNome).length == 0){
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: 'Amostra não respeita o filtro de Convênio.',
                                        type: 'is-warning',
                                        queue: false
                                    });
                                    continue;
                                }
                            }

                            this.amostras.push(data[i]);
                            this.checkedRows.push(data[i]);
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.erros.push(error);
                    this.loading = false;
                }); 

            },
            enviarCheckpoint(){
                if(this.checkedRows.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Nenhuma amostra selecionada.',
                        type: 'is-info',
                        queue: false
                    })

                    return;
                }

                this.erros = [];
                this.loading = true;

                if(this.bancada){
                    localStorage.emitirBancadaCheckpoint = true

                    this.carregarOpcoesBancada();

                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Amostras enviadas. O PDF da bancada pode levar alguns minutos para ser gerado, será apresentado em nova aba.',
                        type: 'is-info',
                        queue: false
                    })
                }
                else {
                    localStorage.emitirBancadaCheckpoint = false
                }

                const amostraLoteCheckpoint = {
                    amostras: this.checkedRows, 
                    funcionarioId: this.funcionarioId,
                    EnviaParaIntegradorId: this.integradorId, 
                    temperatura: this.temperatura, 
                    umidade: this.umidade, 
                    latitude: this.latitude, 
                    longitude: this.longitude,
                    emitirBancada: this.bancada,
                    opcoesBancada: this.opcoesBancada,
                    equipmentId: this.equipmentId
                };

                this.$http.post('/api/atendimento/AmostraLoteCheckpoint', amostraLoteCheckpoint, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        if(this.bancada){
                            const file = new Blob([data], { type: 'application/pdf' });
                            const fileURL = window.URL.createObjectURL(file);
                            window.open(fileURL);
                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.erros.push(error);
                        this.loading = false;
                    }); 

                this.amostras = [];
                this.checkedRows = [];
            },
            carregarOpcoesBancada() {
                this.opcoesBancada = '';

                this.carregarOpcao('SeparaSetoresPorPagina');
                this.carregarOpcao('SeparaLocaisPorPagina');
                this.carregarOpcao('SeparaExamesPorPagina');
                this.carregarOpcao('SeparaPacientesPorPagina');
                this.carregarOpcao('SomenteExamesUrgentes');
                this.carregarOpcao('SomenteExamesSemResultado');
                this.carregarOpcao('ExibeConvenio');
                this.carregarOpcao('ExibeNomeDoMedico');
                this.carregarOpcao('ExibeMaterial');
                this.carregarOpcao('ExibeConservante');
                this.carregarOpcao('ExibeLocal');
                this.carregarOpcao('ExibeDataDeRetorno');
                this.carregarOpcao('ExibeLeito');
                this.carregarOpcao('ExibeRascunho');
                this.carregarOpcao('ExibeUltimosResultados');
                this.carregarOpcao('ExibeNascimento');
                this.carregarOpcao('OrdenaPeloCodigoDaGuia');
                this.carregarOpcao('OrdenaPeloNomeDoPaciente');
                this.carregarOpcao('OrdenaPelaDataDeSolicitacao');
                this.carregarOpcao('EmiteEmPapelRazao');
                this.carregarOpcao('IncluiEspacoParaAssinaturas');
                this.carregarOpcao('ExibeCodigoDeBarrasDaAmostra');
                this.carregarOpcao('ExibeObservacaoPaciente');
            },
            carregarOpcao(nome) {
                const pre = "bancada";

                if (localStorage.getItem(pre + nome) && localStorage.getItem(pre + nome) == 'true') {
                    this.opcoesBancada += this.opcoesBancada + nome + ",";
                }
            },
            imprimir(){
                const modelo = window.localStorage.getItem("etiquetaAtual");

                if (this.config.utilizaValidacaoHimms == null || this.config.utilizaValidacaoHimms == false) {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.checkedRows?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }
                else {
                    this.$notificacaoHub.invoke("EtiquetaAmostra", 
                        this.amostras?.map(item => {
                            return item.amostraId;
                        })
                    , modelo);
                }
            },            
            getSetores(text) {
                if(text){
                    this.$http.get('/search/Setor?nome=' + text)
                        .then(res => {
                            this.setores = res.data.map(x => x.nome);
                        });
                }
            },
            getConvenios(text){
                if(text){
                    this.$http.get('/search/Convenio?nome=' + text)
                        .then(res => {
                            this.convenios = res.data.map(x => x.nome);
                        });
                }
            },
            confirmarIncidencia(amostra){
                this.amostras.push(amostra);
                this.checkedRows.push(amostra);
            },
            incidencia(amostra){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRecebimentoIncidencia,
                    props: {
                        amostra: amostra,
                    },
                    events: {
                        confirmarIncidencia: this.confirmarIncidencia
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            }
        }
    }
</script>
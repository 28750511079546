<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                            
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent">
                        <article class="tile is-child">                            
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Códigos integrações
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Código</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select placeholder="Integrador" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        Adicionar código
                                    </button>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('conservante-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,                   
                    codigosIntegrador: [],
                },

                salvandoDados: false,
            };
        },
        components: {
            titlebar
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/conservante?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                      
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Conservante',
                    this.model.id ?? 'novo'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id){
                    delete newItem.id;
                }
                if (newItem.codigosIntegrador){
                    newItem.codigosIntegrador = newItem.codigosIntegrador.map(i => {
                        return {
                            codigo: i.codigo,
                            integradorConservanteId: {
                                codigo: i.codigo,
                                integradorId: i.integradorId
                            }
                        }
                    }).filter(i => i.integradorConservanteId?.integradorId?.toString()?.trim());
                }


                this.$http.post('/api/manutencao/conservante', newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar conservante: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            }          
        }
    }
</script>
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button type="button" class="delete"></button>
                    A tabela de preços está sendo salva, este processo pode levar alguns minutos.
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="model.tipo" v-slot="{ errors, valid }">
                                        <b-field label="Tipo"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input v-if="model.id" type="text" v-model="model.tipo" readonly></b-input>
                                            <b-select v-else placeholder="Selecione o tipo" class="is-fullwidth" v-model="model.tipo">
                                                <option value="Moeda">Moeda</option>
                                                <option value="AMB">AMB</option>
                                                <option value="CBHPM">CBHPM</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'TabelaPreco'" :chave="model.id"></historico-alteracoes>
                        </article>
                    </div>
                </div>
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-5">
                            <b-field expanded class="search-area">
                                <b-input :placeholder="'Procurar por '+colunaSelecionada"
                                        v-model="procurar"
                                        icon="magnify"
                                        expanded
                                        icon-clickable
                                        @icon-click="loadData"
                                        @input="loadData"></b-input>
                                <b-dropdown aria-role="list" v-model="colunaSelecionada">
                                    <button type="button" class="button is-primary dropdown-opcoes" slot="trigger" slot-scope="{ active }">
                                        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                    </button>

                                    <b-dropdown-item aria-role="listitem" value="Apelido">Apelido</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" value="Nome">Nome</b-dropdown-item>
                                    <hr />
                                    <b-dropdown-item aria-role="listitem" :value="coluna" v-for="coluna in colunas" :key="coluna.id">{{coluna.nome}}</b-dropdown-item>
                                </b-dropdown>                             
                            </b-field>
                        </div>
                        <div class="column is-flex ">                          
                            <b-button icon-left="percent" @click="alterarPercentual = !alterarPercentual">
                                Reajustar valores
                            </b-button>
                            <b-switch @input="bloquearExames" v-model="bloquear">
                                Bloquear Todos Exames ? 
                            </b-switch>                               
                            <b-switch @input="loadData" v-model="exibirBloqueados">
                                Exibir Apenas Bloqueados
                            </b-switch>                              
                        </div>
                    </div>

                    <article v-if="alterarPercentual" class="notification">
                        <div class="columns">
                            <div class="column">
                                <b-field label="Qual o percentual que deseja alterar nos valores da tabela?">
                                    <b-input type="number" class="column is-one-third" v-model="percentual"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <div class="buttons">
                                    <b-button icon-left="check" type="is-success" @click="alterarExames">Sim</b-button>
                                    <b-button icon-left="close" type="is-danger" @click="alterarPercentual = !alterarPercentual">Não</b-button>
                                </div>
                            </div>
                        </div>
                    </article>


                    <div class="columns">
                        <div class="column is-12">
                            <b-table v-if="lista.length != 0" :data="lista"
                                     :loading="loading"
                                     paginated
                                     striped
                                     hoverable
                                     :total="total"
                                     @page-change="onPageChange"
                                     aria-next-label="Próxima página"
                                     aria-previous-label="Página anterior"
                                     aria-page-label="Página"
                                     aria-current-label="Página atual"
                                     :default-sort-direction="defaultOrdenar"
                                     :default-sort="[ordenarPor, ordenar]"
                                     sort-icon-size="is-small"
                                     @sort="onSort">

                                <template slot-scope="props">
                                    <b-table-column field="Apelido" label="Apelido">
                                        {{ props.row.exameApelido }}
                                    </b-table-column>

                                    <b-table-column field="Nome" label="Nome">
                                        {{ props.row.exameNome }}
                                    </b-table-column>

                                    <b-table-column field="" label="adicional" >
                                        {{getCodigo(props.row)}}
                                    </b-table-column>                         


                                    <b-table-column v-if="model.tipo == 'Moeda'" field="Valor" label="Valor">
                                        <input type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />
                                    </b-table-column>

                                    <b-table-column v-if="model.tipo == 'AMB'" field="Valor" label="Qtde. US">
                                        <input type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />
                                    </b-table-column>

                                    <b-table-column v-if="model.tipo == 'CBHPM'" field="PercentualPorte" label="PercentualPorte">
                                        <input type="text" class="input" v-model="props.row.percentualPorte" name="PercentualPorte" />
                                    </b-table-column>

                                    <b-table-column v-if="model.tipo == 'CBHPM'" field="CustoOperacional" label="CustoOperacional">
                                        <input type="text" class="input" v-model="props.row.custoOperacional" name="CustoOperacional" />
                                    </b-table-column>

                                    <b-table-column v-if="model.tipo == 'CBHPM'" field="ValorCBHPM" label="ValorCBHPM">
                                        <b-select class="is-fullwidth" v-model="props.row.ValorCBHPM.Id">
                                            <option v-for="valorCBHPM in model.valoresCBHPM"
                                                    :key="valorCBHPM"
                                                    :value="valorCBHPM">
                                                {{ valorCBHPM }}
                                            </option>
                                        </b-select>
                                    </b-table-column>

                                    <b-table-column field="Bloqueado" label="Bloqueado" centered>
                                        <b-checkbox class="level-item" v-model="props.row.bloqueado">
                                        </b-checkbox>
                                        <b-field>
                                            <b-input v-if="props.row.bloqueado" placeholder="Motivo do bloqueio"
                                                     v-model="props.row.motivoBloqueio"></b-input>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="RequerAutorizacao" label="Autorização" centered>
                                        <b-checkbox v-model="props.row.requerAutorizacao">
                                        </b-checkbox>
                                    </b-table-column>

                                    <b-table-column field="ValorNegociado" label="Negociado" centered>
                                        <b-checkbox v-model="props.row.valorNegociado">
                                        </b-checkbox>
                                    </b-table-column>
                                </template>

                                <template slot-scope="props" slot="header">
                                    <span v-if="props.column.label != 'adicional'">{{props.column.label}}</span>
                                    <span v-if="props.column.label == 'adicional'">
                                        <b-field label="">
                                            <b-select placeholder="Selecione um Código" v-model="colunaDinamica">
                                                <option
                                                    v-for="option in colunas"
                                                    :value="option.nome"
                                                    :key="option.id">
                                                    {{ option.nome }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                    </div>
                </article>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('tabelapreço-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapMutations, mapState, mapGetters } from 'vuex'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar,
            historicoAlteracoes
        },
        data() {
            return {
                model: {
                    id: null,
                    tipo: null,
                    nome: null,
                    valoresCBHPM: [],
                    exames: []
                },
                lista: [],
                listaCompleta: [],
                total: 0,
                loading: true,
                procurar: null,
                ordenarPor: 'Apelido',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 9999,
                alterarPercentual: false,
                percentual: 0,
                bloquear: false,
                salvandoDados: false,
                exibirBloqueados: false,
                colunas: [],
                colunaSelecionada: 'Apelido',
                colunaDinamica: null
            }
        },
        created() {
            this.$http.get('/api/manutencao/tabelapreco?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;
                    
                });
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Tabela de Preços',
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            modalHistoricoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalHistoricoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_HISTORICO')
                }
            },
        },
        methods: {
            loadData() {
                if(this.listaCompleta.length > 0) {
                    this.model.exames = this.listaCompleta;
                }

                let examesFiltrados = this.model.exames;
                
                if(this.procurar){
                    if(this.colunaSelecionada == 'Apelido'){
                        this.colunaDinamica = null;
                        examesFiltrados = examesFiltrados.filter(item => item.exameApelido.toUpperCase().indexOf(this.procurar.toUpperCase()) != -1);
                    }
                    else if(this.colunaSelecionada == 'Nome'){
                        this.colunaDinamica = null;
                        examesFiltrados = examesFiltrados.filter(item => item.exameNome.toUpperCase().indexOf(this.procurar.toUpperCase()) != -1);
                    }
                    else {           
                        if(this.listaCompleta.length == 0) {
                            this.listaCompleta = this.model.exames;
                        }

                        this.loadAsyncData();   
                        examesFiltrados = [];
                    }
                }                
                
                
                if(this.exibirBloqueados){
                    
                    examesFiltrados = examesFiltrados.filter(item => item.bloqueado);
                }

                this.lista = examesFiltrados;
                this.$forceUpdate();
                
            },
            loadAsyncData() {

                let tipoCodigo = 0;
                let fieldName = this.colunaSelecionada;
                const isObject = (obj) => {
                    const type = typeof obj;
                    return type === 'function' || type === 'object' && !!obj;
                };
                if (this.colunaSelecionada && isObject(this.colunaSelecionada)){
                    tipoCodigo = this.colunaSelecionada.id;
                    fieldName = `Codigo_${tipoCodigo}`;
                    this.selecionaColunaTabela();
                }

                const params = [
                    `id=${this.$route.params.id}`,
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `fieldName=${fieldName}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/tabelaprecoexames?${params}`)
                    .then(({ data }) => {
                        data.lista = data.lista?.map(p => {
                            p.valor = parseFloat(p.valor).toFixed(2);                                                         
                            p.ValorCBHPM = {Id: p.cbhpmId, Valor: p.cbhpmValor};
                            return p;
                        })

                        this.lista = data.lista
                        this.model.exames = data.lista
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        
                        this.lista.forEach((item, index) => {
                            if(index < 20){
                                this.$http.get('/api/manutencao/TabelaPrecoExamesCodigos?exameId=' + item.exameId)
                                    .then(({ data }) => {
                                        item.exameCodigos = data.lista
                                    })
                            }
                        });

                        if(this.lista.filter(item => !item.bloqueado ) == 0 )
                        {
                            this.bloquear = true;
                        }

                        this.colunas = data.codigos?.map(c => {
                            return {
                                ...c,
                                toString: () => {
                                    return c.nome;
                                }
                            }
                        });
                    })
                    .catch((error) => {
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/tabelapreco`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.push({ name: 'tabelasprecos' })
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            },
            onPageChange(page) {
                this.page = page
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            bloquearExames() {                
                for (const i in this.model.exames) {
                    this.model.exames[i].bloqueado = this.bloquear;
                }                          
            },
            getCodigo(row){
                if (!this.colunaDinamica) return '';

                return row.exameCodigos?.find(c => c.exameCodigoTipoNome === this.colunaDinamica)?.codigo;
            },
            selecionaColunaTabela(){
                if (this.colunaSelecionada){
                    this.colunaDinamica = this.colunaSelecionada.nome;
                }
            },
            alterarExames() {
                this.alterarPercentual = !this.alterarPercentual;

                for (const i in this.model.exames) {
                    if (this.model.exames[i].valor != null) {
                        this.model.exames[i].valor = (parseFloat(this.model.exames[i].valor) + (parseFloat(this.model.exames[i].valor) * (parseFloat(this.percentual) / 100))).toFixed(2);
                    }
                }
            },
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_HISTORICO'
            ])
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>
<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <div class="box">
      <div class="columns">
        <div class="column is-6">
          <label class="label fix-margem-left-local-origem">Geladeira</label>
          <searchIdName
            :ponto="false"
            label=""
            table="Geladeira"
            :id.sync="item.geladeiraId"
          ></searchIdName>
        </div>
        <div class="column is-6">
          <label class="label fix-margem-left-local-origem">Container</label>
          <searchIdName
            :ponto="false"
            label=""
            table="Container"
            :id.sync="item.containerId"
          ></searchIdName>
        </div>
      </div>
      <div class="columns fix-margin center">
        <b-button
          type="is-primary"
          icon-left="filter"
          class="center"
          @click.prevent.stop="search"
          >Localizar</b-button
        >
      </div>
    </div>
    <div class="box" v-if="model.posicoes != null && model.posicoes.length > 0">
      <div class="tile is-child box">
        <div class="columns">
          <div class="column">
            <label>Posições:</label>
            <table class="table table-bordered table-fullsize">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(coluna, x) in colunas" v-bind:key="x">
                    <label class="label">{{ colunas[x] }}</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(linha, i) in linhas" v-bind:key="i">
                  <td>
                    <label class="label">{{ linhas[i] }}</label>
                  </td>
                  <td v-for="(coluna, j) in colunas" v-bind:key="j">
                    <b-field
                      label=""
                      class="cell-size"
                      :type="{
                        'is-danger': posicao(linhas[i], colunas[j]).error,
                      }"
                      :message="posicao(linhas[i], colunas[j]).error"
                    >
                      <b-input
                        :value="posicao(linhas[i], colunas[j]).valor"
                        @input="setarValor(linhas[i], colunas[j], $event)"
                        custom-class="input-text-size"
                        @blur.prevent.stop="
                          buscarAmostra(
                            linhas[i],
                            colunas[j],
                            $event.target.value
                          )
                        "
                        maxlength="20"
                        :has-counter="false"
                      >
                      </b-input>
                    </b-field>
                    <div
                      v-if="posicao(linhas[i], colunas[j]).amostra"
                      class="cell-size"
                    >
                      <p>
                        <b class="input-text-size">
                          {{
                            posicao(linhas[i], colunas[j]).amostra.pacienteNome
                          }}
                        </b>
                      </p>
                      <p class="cell-size">
                        <span
                          class="tag cell-size input-text-size"
                          v-for="ex in posicao(linhas[i], colunas[j]).apelidos"
                          :key="ex"
                          :class="{'is-success': ex.assinado, 'is-warning': ex.bloqueado, 'is-default': !ex.assinado && !ex.bloqueado}">
                            {{ ex.apelido }}
                        </span>
                      </p>
                    </div>
                    <!-- <span>{{linha}}{{coluna}}</span> -->
                    <input
                      type="hidden"
                      :value="
                        (model.posicoes[i * colunas.length + j].numero =
                          linhas[i])
                      "
                    />
                    <input
                      type="hidden"
                      :value="
                        (model.posicoes[i * colunas.length + j].letra =
                          colunas[j])
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <nav class="level">
      <div class="level-item">
        <p class="buttons">
          <b-button
            type="is-success"
            :loading="salvandoDados"
            native-type="submit"
            icon-left="check-circle"
            @click.prevent.stop="save"
            :disabled="!valores.length"
          >
            Salvar
          </b-button>
          <b-button
            type="is-warning"
            native-type="button"
            icon-left="close-circle"
            @click="limparTodas()"
            :disabled="!valores.length"
          >
            Limpar
          </b-button>
        </p>
      </div>
    </nav>
  </section>
</template>

<style>
.center {
  margin: 0 auto;
}

.cell-size {
  width: 7rem;
  /* font-size: 8px; */
}

.table-fullsize {
  width: 100%;
}

.is-default {
  background-color: #777 !important;
  color: #fff !important;
}

.input-text-size {
  font-size: 0.8rem !important;
}
</style>

<script>
import titlebar from "@/components/titlebar.vue";
import searchIdName from "@/components/searchIdName.vue";

export default {
  components: {
    titlebar,
    searchIdName,
  },
  data() {
    return {
      valores: [],
      model: {
        id: 0,
        nome: null,
        posicoes: [],
      },
      item: {
        geladeiraId: null,
        containerId: null,
      },
      salvandoDados: false,
      qtdeColunas: null,
      qtdeLinhas: null,
      colunas: [],
      linhas: [],
      alfabeto: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  created() {
    this.$http
      .get("/api/manutencao/sorotecacontainer?id=" + this.$route.params.id)
      .then((res) => {
        this.model = res.data;
        this.geraPosicoes();
      });
  },
  computed: {
    titleStack() {
      return ["Análise", "Soroteca - Armazenar"];
    },
  },
  methods: {
    posicao(numero, letra) {
      const valor = this.valores?.find(
        (v) => v.numero === numero && v.letra === letra
      );
      if (!valor) {
        return { valor: null, error: null };
      }

      return valor;
    },
    limparTodas(){
        this.valores = this.valores?.map(v => {
            if (v.amostra){
                v.amostra.codigoDeBarras = null;
                v.amostra.amostraCodigoDeBarras = null;
                v.deleted = true;
                v.changed = true;
            }
            return v;
        });

        return this.save();
    },
    setarValor(numero, letra, valor) {
      const item = this.valores?.find(
        (v) => v.numero == numero && v.letra == letra
      );
      if (!item) {
        this.valores.push({ numero: numero, letra: letra, valor: valor });
      } else {
        item.valor = valor;
      }

      item.amostra.codigoDeBarras = valor;
      item.changed = true;
      if (!valor || valor?.trim() === "") {
        item.deleted = true;
      }
    },
    buscarAmostra(numero, letra, codeBarras) {
      const item = this.valores?.find(
        (v) => v.numero === numero && v.letra === letra
      );
      if (
        item &&
        codeBarras &&
        (!item.amostra || item.amostra?.amostraCodigoDeBarras != codeBarras)
      ) {
        item.error = null;
        item.new = null;
        this.$http
          .get(
            `/api/analitico/SorotecaArmazenarContainerAmostra?codigoDeBarras=${codeBarras}`
          )
          .then((result) => {
            if (!result.data?.length) {
              item.error = "Amostra não encontrada";
              item.valor = null;
              item.amostra = null;
              this.$forceUpdate();
            } else {
              const valor = result.data[0];
              const posicao = this.posicao(numero, letra);
              this.$buefy.dialog.confirm({
                message:
                  "Existem exames não prontos nesta amostra. Deseja incluir mesmo assim?",
                onConfirm: () => {
                  item.error = null;
                  item.new = true;
                  item.changed = true;
                  item.valor = codeBarras;
                  item.geladeira = this.model.geladeira;
                  item.container = this.model.container;
                  item.posicao = {
                    id: posicao.id,
                    letra: posicao.letra,
                    numero: posicao.numero,
                    container: this.model.container,
                  };
                  item.amostra = {
                    id: valor.amostra?.amostraId,
                    codigoDeBarras: codeBarras,
                    pacienteNome: valor.amostra?.pacienteNome
                  };
                  item.apelidos = valor.apelidos;
                  this.$forceUpdate();
                },
                cancelText: "Cancelar",
                confirmText: "Confirmar",
                onCancel: function () {
                  item.amostra = null;
                  item.error = "Amostra não disponível";
                  item.valor = null;
                },
              });
            }
          });
      }
    },
    search() {
      if (!this.item.containerId && !this.item.geladeiraId) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "O container e a geladeira precisam ser selecionados",
          type: "is-danger",
          queue: false,
        });

        return;
      }
      const params = [];
      this.salvandoDados = true;
      Object.keys(this.item).forEach((key) => {
        const value = this.item[key];
        if (value) {
          params.push(`${key}=${value}`);
        }
      });
      this.$http
        .get(
          `/api/analitico/SorotecaArmazenarContainer?${params.join("&")}`,
          this.model
        )
        .then((response) => {
          console.log(response.data);
          this.salvandoDados = false;
          this.model = response.data;
          this.model.posicoes = response.data.container?.posicoes?.length
            ? [...response.data.container?.posicoes]
            : [];
          this.valores = [...response.data.container?.posicoes];
          delete response.data.container?.posicoes;
          this.geraColunasLinhas();
          this.popularValores(response.data.soroteca);
        })
        .catch((error) => {
          console.log(error);
          this.salvandoDados = false;
          throw error;
        });
    },
    retornaColuna(posicao) {
      if (posicao >= this.alfabeto.length) {
        return (
          this.alfabeto[posicao - this.alfabeto.length] +
          (posicao % this.alfabeto.length)
        );
      } else {
        return this.alfabeto[posicao];
      }
    },
    geraColunasLinhas() {
      if (this.model.posicoes != null) {
        this.colunas = Array.from(
          this.groupBy(this.model.posicoes, (posicao) => posicao.letra).keys()
        );
        this.linhas = Array.from(
          this.groupBy(this.model.posicoes, (posicao) => posicao.numero).keys()
        );
      }
    },
    geraPosicoes() {
      const posicoes = [];
      for (let i = 1; i <= this.qtdeColunas; i++) {
        for (let j = 0; j < this.qtdeLinhas; j++) {
          posicoes.push({ id: 0, numero: i, letra: this.retornaColuna(j) });
        }
      }

      this.model.posicoes = posicoes;
      this.geraColunasLinhas();
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    popularValores(sorotecaArray) {
      sorotecaArray?.forEach((i) => {
        const valor = this.valores?.find(
          (v) => v.letra === i.posicao?.letra && v.numero === i.posicao?.numero
        );
        const amostraValor = i.amostra;
        if (valor && amostraValor) {
            valor.amostra = amostraValor;
            valor.apelidos = i.apelidos;
            valor.amostra.id = amostraValor.amostraId;
            valor.valor = amostraValor.amostraCodigoDeBarras;
            valor.codigoDeBarras = amostraValor.amostraCodigoDeBarras;
            valor.changed = false;
            valor.id = i.id;
            valor.sorotecaId = i.sorotecaId;
        }
    });
    },
    save() {
      const data = this.valores
        ?.filter((i) => i.amostra && i.changed)
        .map((v) => {
          const amostra = v.amostra;
          if (amostra) {
            amostra.codigoDeBarras = amostra.amostraCodigoDeBarras;
          }
          let id = !v.new ? v.id : 0;
          if (v.deleted){
              id = v.sorotecaId;
          }
          return {
            amostra: amostra,
            container: v.container,
            geladeira: this.model.geladeira,
            posicao: {
              id: v.id,
              letra: v.letra,
              numero: v.numero,
            },
            id: id,
          };
        });
      this.$http
        .post(`/api/analitico/SorotecaArmazenarContainer`, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.$router.push("/analitico/soroteca-localizar");
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              message: "Ocorreu um erro ao salvar",
              type: "is-danger",
              queue: false,
            });
          }
        });
    },
  },
};
</script>

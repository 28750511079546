<template>
  <section class="hero is-medium">
    <div class="hero-body">
      <div class="container has-text-centered">
        <img alt="LaborLis logo" src="../assets/logo.png">
        <h2 class="subtitle">
          Sistema de informação para laboratórios de análises clínicas
        </h2>
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <article class="message is-small">
              <div class="message-header">
                <p>Este sistema está licenciado para:</p>
              </div>
              <div class="message-body">
                <strong>{{config.razaoSocial}}</strong>
                <p>CNPJ: {{config.cnpj}}</p>
              </div>
            </article>                    
          </div>
        </div>        
        <h2 class="subtitle">
          <span class="tag is-black">versão {{config.releaseNumber}}</span>
          <span class="tag is-info">{{config.releaseDate}}</span>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState([
                'unidadeId',
                'config'
            ]),
        }
    }
</script>

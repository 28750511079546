<template>
    <div class="panel">
        <div class="panel-heading">
            {{$t('FILTROS.FILTRO')}}
        </div>
        <div class="panel-block columns is-multiline">
                <div v-if="(tipo!='Integracoes' && tipo!='Resultados')" class="column is-6  is-full-mobile">
                    <label class="label">{{$t('FILTROS.PACIENTE')}}</label>
                    <b-input v-model="filtros.paciente" name="paciente" :placeholder="$t('FILTROS.PROCURAR')"/>
                </div>

                <div v-if="(tipo=='Integracoes')" class="column is-6">
                    <label class="label">{{$t('FILTROS.INTEGRADORES')}}</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select :placeholder="$t('FILTROS.INTEGRADOR')" v-model="filtros.integradorId" style="width:100%;">                                
                                <option v-for="option in integradores"
                                        :value="option.id"
                                        :key="option.id">
                                    {{ option.nome }}
                                </option>
                            </select>                            

                        </span>

                    </div>
                </div>                


                <div v-if="( tipo=='Resultados')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.local.id" :item.sync="filtros.local" table="Local" :label="$t('FILTROS.LOCAL')"/>
                </div>
                <div class="column is-6">
                    <label class="label">{{$t('FILTROS.PERIODO')}}</label>                    
                    <periodoHora :dataInicial.sync="filtros.datas[0]" :dataFinal.sync="filtros.datas[1]"/>                                            
                </div>                

                <div v-if="(tipo=='Local')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.convenio.id" :item.sync="filtros.convenio" table="Convenio" :label="$t('FILTROS.CONVENIO')"/>
                </div>
                <div v-if="(tipo=='Local')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.medico.id" :item.sync="filtros.medico" table="Medico" :label="$t('FILTROS.MEDICO')"/>
                </div>
                <div v-if="(tipo=='Convenio' || tipo=='RelacaoExames')" class="column is-6 is-full-mobile">
                    <searchIdName :id.sync="filtros.local.id" :item.sync="filtros.local" table="Local" :label="$t('FILTROS.LOCAL')"/>
                </div>

                <div class="column is-6 is-full-mobile" v-if="(tipo=='Local') || (tipo=='Convenio')" >
                    <label class="label">{{$t('FILTROS.SITUACAO')}}</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select placeholder="Situação" v-model="filtros.filtrarPor" style="width:100%;">                                
                                <option value="todos" checked > {{$t('FILTROS.LOCAL')}}</option>
                                <option value="parciais" > {{$t('FILTROS.PARCIAIS')}}</option>
                                <option value="prontos" > {{$t('FILTROS.PRONTOS')}}</option>
                                <option value="atrasados" > {{$t('FILTROS.ATRASADOS')}}</option>
                                <option v-if="(tipo=='Convenio')" value="recoleta" > {{$t('FILTROS.RECOLETA')}}</option>
                            </select>                            

                        </span>

                    </div>
                </div>  

                <div class="column is-4 is-full-mobile" v-if="(tipo=='Resultados')">
                    <label class="label">{{$t('FILTROS.FILTRAR')}}</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select :placeholder="$t('FILTROS.FILTRAR')" v-model="filtros.filtrarPor" style="width:100%;">                                
                                <option value="solicitacao" checked >{{$t('FILTROS.DATASOLICITACAO')}}</option>
                                <option value="retorno" >  {{$t('FILTROS.DATARETORNO')}}</option>
                                <option value="assinatura" >{{$t('FILTROS.DATAASSINATURA')}}</option>
                            </select>                            

                        </span>

                    </div>
                </div>  

                <div class="column is-4 is-full-mobile"  v-if="(tipo=='Resultados')">   
                    <b-switch v-model="filtros.somenteNaoImpressos">
                        {{$t('FILTROS.RESULTADOIMPRESSO')}}
                    </b-switch>                    
                </div>                

                <div class="column is-4 is-full-mobile" v-if="(tipo=='Resultados')" >   
                    <b-switch v-model="filtros.somentePortal">
                        {{$t('FILTROS.EXAMEPORTAL')}}
                    </b-switch>                    
                </div>                

        
                <div class="column is-6 is-full-mobile"  v-if="( tipo=='Amostras')">
                    <label class="label"> {{$t('FILTROS.ORDEM')}}</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select :placeholder="$t('FILTROS.ORDEM')" v-model="filtros.ordenarPor" style="width:100%;">                                
                                <option value="emissao" checked > {{$t('FILTROS.DATAEMISSAO')}}</option>
                                <option value="guia" > {{$t('FILTROS.NUMEROGUIA')}}</option>
                                <option value="paciente" >{{$t('FILTROS.PACIENTENOME')}}</option>
                                <option v-if="( tipo!='Amostras')" value="alteracao" > {{$t('FILTROS.MODIFICACAO')}}</option>
                                <option v-if="( tipo!='Amostras')" value="retorno" >{{$t('FILTROS.DATARETORNO')}}</option>
                            </select>                            

                        </span>

                    </div>
                </div> 

                <div class="column is-6 is-full-mobile" v-if="(tipo=='Amostras')">
                    <label class="label">{{$t('FILTROS.SITUACAO')}}</label>
                    <div class="control" style="width:100%">
                        <span class="select is-empty" style="width:100%">

                            <select :placeholder="$t('FILTROS.SITUACAO')" v-model="filtros.filtrarPor" style="width:100%;">
                                <option value="todos" checked>{{$t('FILTROS.TODOS')}}</option>
                                <option value="semLote">{{$t('FILTROS.PEDIDOSLOTE')}}</option>
                                <option value="enviados">{{$t('FILTROS.LOTESENVIADO')}}</option>
                                <option value="envNaoRecebidos">{{$t('FILTROS.LOTENAORECEBIDO')}}</option>
                                <option value="recebidos"> {{$t('FILTROS.LOTERECEBIDO')}}</option>
                                <option value="amostraNaoImpressa">{{$t('FILTROS.AMOSTRAIMPRESSA')}}</option>
                            </select>                            

                        </span>

                    </div>
                </div>                                                    
           

        </div>
        <div class="panel-block">
            <b-button icon-left="magnify" type="is-primary" :loading="loading" @click="carregarGuias()">{{$t('FILTROS.BUSCAR')}}</b-button>            
            <b-button  v-if="(tipo!='Amostras' && tipo!='Resultados'  && tipo!='RelacaoExames')" type="is-danger" native-type="button" icon-left="close-circle" @click="fecharModal()">{{$t('FILTROS.CANCELAR')}}</b-button>            
                
        </div>
    </div>
</template>


<style scoped>

hr{
    margin: 0.5rem 0  !important;
}


.label-filtros{
    min-height: 36px !important;
    font-size: 15px !important

}


</style>



<script>
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            searchIdName,
            periodoHora
        },
        props: {
            pesquisas: Array,
            tipo: String,
        },
        data() {
            return {
                filtros: {
                    somentePortal:false,
                    somenteNaoImpressos:false,
                    filtrarPor: (this.tipo == 'Resultados') ? 'solicitacao'  : 'todos',
                    integradorId:null,
                    ordenarPor: 'emissao',
                    medico: {
                        id: null
                    },
                    local: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    paciente: null,
                    datas: [
                        this.$moment().subtract(7, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                },
                loading: false,
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario',
                'integradores',
                
            ])
        },
        methods: {
            carregarGuias(){
                this.$emit('filtrar', this.filtros);
            },
            fecharModal(){
                this.$emit('fecharModal');
            },             
        }
    }

</script>
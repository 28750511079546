export default {
    LANGUAGE:{
        NAME:"Espanhol"
    },
    PORTAL:{
        SAUDACOES:"Hola,",
        INSTRUCOES: "A través de esta página puede seguir todas las pruebas solicitadas en este laboratorio.",
        PESQUISA: "Con el fin de mejorar nuestros servicios, le pedimos que responda a las siguientes encuestas",
        ANALISE: "Análisis clínicos",
        RADIOLOGIA: "Radiología",
        NUMEROACESSO: "Número de acceso",
        VIEWER: "Visor",
        DATAEMISSAO: "Fecha de emisión",
        RESULTADO: "Resultado",
    },
    GUIAS:{
        DATAEMISSAO: "Fecha de emisión",
        NUMEROGUIA: "Número de la guía",
        PACIENTENOME: "Nombre del paciente",
        FILTROS: "Filtros",
        GUIA: "Guía",
        RETORNO: "Regreso",
        PACIENTE: "Paciente",
        RACAESPECIE: "Raza/Especies",
        MEDICO: "Doctor",
        DATASOLICITACAO: "Fecha de la solicitud",
        DATAENTREGA: "Fecha de entrega",
        DATAPREVISTA: "Fecha prevista",
        PRECO: "Precio",
        AUTORIZACAO: "Sin autorización del seguro de salud!",
        CONTATO: "Póngase en contacto con el laboratorio.",
        ABRIRRESULTADOS: "Resultados abiertos",
        EDITAR: "Editar la guía",
        ETIQUETA: "Imprimir la etiqueta",
        COMPROVANTE: "Imprimir bono",
        ENVIAEMAIL: "Enviar por correo electrónico",
        VERMAIS: "Haga clic para ver más información sobre cada examen",
        PRONTOS: "Exámenes listos",
        SEMRESULTADO: "No se han encontrado exámenes para los filtros seleccionados!",
    },
    PESQUISASATISFACAO:{
        TITULOPORTAL: "Portal",
        TITULOPESQUISA: "Encuesta de satisfacción",
        SALVAR: "Guardar",
        VOLTAR: "Volver al"
    },
    FILTROS:{
        FILTRO: "Filtros",
        PACIENTE: "Paciente",
        PERIODO: "Período",
        PROCURAR: "Búsqueda por nombre/fecha de nacimiento/código de guía",
        INTEGRADORES: "Integradores",
        INTEGRADOR: "Integrador",
        LOCAL: "Sitio",
        CONVENIO: "Seguro de salud",
        MEDICO: "Doctor",
        TODOS: "Todo",
        PARCIAIS: "Parciales",
        PRONTOS: "Listo",
        ATRASADOS: "Atrasos",
        RECOLETA: "Recoger",
        FILTRAR: "Filtro por",
        DATASOLICITACAO: "Fecha de la solicitud",
        DATARETORNO: "Fecha de regresso",
        DATAASSINATURA: "Fecha firma",
        RESULTADOIMPRESSO: "Solo resultados no impresos",
        EXAMEPORTAL: "Sólo exámenes del portal",
        ORDEM: "Clasificar por",
        DATAEMISSAO: "Fecha de emisión",
        NUMEROGUIA: "Número de la guía",
        PACIENTENOME: "Nombre del paciente",
        MODIFICACAO: "Última modificación",
        SITUACAO: "Situación",
        PEDIDOSLOTE: "Solicitudes sin lote",
        LOTESENVIADO: "Lotes enviados",
        LOTENAORECEBIDO: "Lotes enviados y no recibidos",
        LOTERECEBIDO: "Lotes recibidos",
        AMOSTRAIMPRESSA: "Muestras sin imprimir",
        BUSCAR: "Busque en",
        CANCELAR: "Cancelar"
    },
    ORCAMENTO:{
        EXAMES: "Exámenes",
        EXAMESENCONTRADOS: "No se han encontrado examen",
        EXAME: "Examen",
        APELIDO: "Apellido",
        NOME: "Nombre",
        PRECO: "Precio",
        TOTALEXAME: "Exámenes totales",
        BLOQUEADO: "Bloqueo",
    },
    PACIENTESCONVENIO:{
        ID: "Id",
        BUSCARPORNOME: "Buscar por nombre",
        INCLUIR: "Incluya",
        NOME: "Nombre",
        SEXO: "Sexo",
        NASCIMENTO: "Nacimiento",
        INSCRICAO: "Inscripción",
        CPF: "Seguro social",
    },
    LOTES:{
        CODIGOBARRAS: "Código de barras",
        INCLUIR: "Incluya",
        INCLUIRPENDENCIA: "Incluir pendiente",
        CONTADOR: "Contador",
        AMOSTRA: "Muestra",
        PACIENTE: "Paciente",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        EXAMES: "Exámenes",
        TEMPERATURA: "Temperatura",
        LATITUDE: "Latitud",
        LONGITUDE: "Longitud",
        ENVIAR: "Enviar",
    },
    CATALOGOEXAMES:{
        APELIDONOME: "Búsqueda por apellido/nombre",
        CODIGO: "Código",
        ID: "Id",
        NOME: "Nombre",
        APELIDO: "Apellido",
        VISUALIZAR: "Ver",
    },
    AMOSTRAS: {
        SELECIONARAMOSTRA: "Seleccionar/deseleccionar todas las muestras",
        IMPRIMIRAMOSTRA: "Muestras de impresión",
        FILTROS: "Filtros",
        EXAMESPERIODO: "Exámenes solicitados en el periodo de "
    },
    AMOSTRA: {
       AMOSTRACANCELADA: "Muestra cancelada" ,
       AMOSTRAEXAMECANCELADA: "Muestra con todas los exámenes anuladas",
       AMOSTRAINCIDENCIA: "Muestra con incidencia",
       CODIGOBARRAS: "Código de barras",
       PACIENTE: "Paciente",
       MATERIAL: "Material",
       CONSERVANTE: "Conservador",
       LOTE: "Lote",
       CONGELADO: "Acondicionamiento: Congelado",
       REFRIGERADA: "Acondicionamiento: Refrigerado",
       AMBIENTE: "Acondicionamiento: Entorno",
       ETIQUETA: "Acondicionamiento: Etiqueta",
       EXAMEASSINADO: "Examen firmado",
       EXAMEDIGITADO: "Examen mecanografiado",
       EXAMECANCELADO: "Examen anulado",
       IDENTIFICACAO: "Identificación"
    },
    RELACAOEXAME: {
        SOLICITADO: "Solicitado en",
        TOTAIS: "Totales",
        GUIAS: "Guías",
        PACIENTES: "Pacientes y",
        EXAMES: "Exámenes",
        EXAMESPERIODO: "Exámenes solicitados en el periodo de "
    },
    VISUALIZAREXAMES:{
        APELIDO: "Apellido",
        NOME: "Nombre",
        QTDEAMOSTRAS: "Cantidad de muestras",
        PRAZODIA: "Días de plazo",
        PRAZOHORA: "Horas límite",
        PRAZOMINUTO: "Plazo del minuto",
        TEMPOJEJUM:  "Tiempo mínimo de ayuno",
        VOLUMEMINIMO: "Volumen deseado",
        MEDICAMENTO: "Medicina",
        DUM:"fecha de la última menstruación",
        PESO: "Peso",
        ALTURA: "Estatura",
        MASCARAS: "Ver las máscaras",
        VERSÃO: "Versión",
        SINONIMIA: "Sinonimia",
        RECIPIENTES: "Contenedores",
        OBTERAMOSTRA: "Obtención de una muestra",
        INFOLABORATORIO: "Información al laboratorio",
        INFOPACIENTE: "Información al paciente",
        APLICACAOOCLINICA: "Aplicación clínica / interpretación",
        CRITREJEICAO: "Criterios de rechazo",
        TABELA: "Tabla de precios",
        BLOQUEADO: "Bloqueo",
        NEGOCIADO: "Negociado",
        VOLTAR: "Volver",
        CODIGO: "Código",
        MATERIAL: "Material",
        CONSERVANTE: "Conservador",
        ESTABILIDADEAMBIENTE: "Estabilidad en el ambiente (h)",
        ESTABILIDADEREFRIGERADO: "Estabilidad en refrigeración (h)",
        ESTABILIDADECONGELADO: "Estabilidad congelada (h)",
    },
    RESULTADOS: {
        IMPRIMIRRESULTADO: "Imprimir los resultados",
        GUIA: "Guía",
        REFERENCIA: "Referencia",
        EMISSAO: "Emisión",
        PACIENTEID: "Paciente Id",
        PACIENTENOME: "Paciente nombre",
        LOCAL: "Sitio",
        LIBERACAO: "Liberar",
        NENHUM: "Ninguna",
        PARCIAL: "Parcial",
        TOTAL: "Total",
        EXAMESPERIODO: "Exámenes solicitados en el periodo de "
    },
    AVISOS: {
       PEDIDO: "Solicitud", 
       AMOSTRA: "Muestra",
       TIPO: "Tipo",
       EXAMES: "Exámenes",
    },
    RECOLETAS: {
        PEDIDO: "Solicitud", 
        CODIGOBARRAS: "Código de barras",
        SOLICITADA: "Solicitado en",
        MOTIVO: "Razón",
        EXAMES: "Exámenes",
        ETIQUETA: "Imprimir la etiqueta",
    },
    NAVBARCONVENIO: {
        GUIAS: "Guías",
        ORCAMENTO: "Presupuesto",
        INCLUIRGUIA: "Incluya la guía",
        ENVIARLOTE: "Enviar el lote",
        CATALOGO: "Catálogo",
        DEEXAMES: "de los exámenes",
        AMOSTRAS: "Muestras",
        EXAMES: "Exámenes",
        RESULTADOS: "Resultados",
        AVISOS: "Avisos",
        RECOLETA: "Recoger",
        REMARCACAO: "Reprogramación",
        VALORESCRIT: "Valores criticos",
        INTEGRACAO: "Integraciones",
    },
    VALORCRITICO: {
        PEDIDO: "Solicitud", 
        EXAMES: "Exámenes",
    },
    GUIA:{
        INDENTIFICACAO: "Identificación",
        REFERENCIA: "Referencia",
        LOCALORIGEM: "Lugar de origen",
        SELECIONELOCAL: "Seleccione su lugar de origen",
        EMITIDA: "Emitido en",
        PACIENTE: "Paciente",
        HORASJEJUM: "Horas de ayuno",
        INSCRICAO: "Inscripción",
        SEXO: "SEXO",
        NASCIMENTO: "Nacimiento",
        MEDICO: "Doctor",
        CBO: "CBO-S",
        EXAMES: "Exámenes",
        AMOSTRAS: "Muestras",
        INCLUIREXAME: "Incluir el examen",
        PROCURAR: "Buscar en",
        APELIDO: "Apellido",
        NOME: "Nombre",
        CÓDIGO: "Código externo",
        IDENTIFICACAO: "Identificación",
        NENHUMEXAME: "No hay examen disponible.",
        BLOQUEADO: "BLOQUEO",
        JEJUM: "Ayuno",
        CANCELARSELECIONADOS: "Cancelar los exámenes seleccionados",
        INCLUIR: "Incluya",
        IMPRIMIRCOMPROVANTE: "Imprimir bono",
        COMPROVANTE: "Recibo",
        OBSERVACOES: "Observaciones",
        HIPOTESE: "Hipótesis diagnóstica",
        CID10: "CID10",
        INDICACAO: "Indicación clínica",
        MEDICAMENTOS: "Medicamentos",
        INCLUIRMEDICAMENTO: "Incluya un medicamento",
        RESPOSTAS: "Respuestas",
        NENHUMARESPOSTA: "No hay respuesta",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MEDICOOBRIGATORIO: "Médico obligatorio",
        MENSAGEMMEDICO: "Especifique un médico para continuar.",
        LOCALOBRIGATORIO: "Lugar de origen obligatorio",
        MENSAGEMLOCAL: "Especifique un lugar de origen para continuar.",
        EXAMEOBRIGATORIO: "Exámenes obligatorios",
        MENSAGEMEXAME: "La guía debe tener al menos 1 examen.",
        MEDICAMENTOOBRIGATORIO: "Medicamento obligatoria",
        MENSAGEMMEDICAMENTO: "Estas pruebas necesitan que informes de los <b>medicamentos</b> en uso.",
        PESOOBRIGATORIO: "Peso obligatorio",
        MENSAGEMPESO: "Confirmar la inclusión del peso del paciente ?",
        ALTURAOBRIGATORIA: "Estatura obligatoria",
        MENSAGEMALTURA: "Confirma la inclusión de la estatura del paciente  ?",
        CONFIRMA: "Confirmar",
        EXAMEINCLUIDO: "Examen ya incluido",
        MENSAGEMINCLUIDO: "Confirma la inclusión del examen: ",
        BLOQUEIOEXAME: "Bloqueo del examen duplicados activo",
        MESSAGEMBLOQUEIO: "Para introducir este examen más de una vez, solicite la eliminación del bloque de examen duplicado en el registro de pruebas",
        BLOQUEIOEXAMEGENERO: "Bloque de examen por género",
        BLOQUEIOEXAMEGENEROMENSAGEM: "Este examen no puede ser elegida para este paciente.",
        INSERIREXAME: "Inclusión del examen",
        MENSSAGEMINSERIREXAME: "El examen ya se ha encontrado",
        NESTAGUIA: "en esta ficha.",
        EXAMECOMPLEMENTARMENSAGEM: "El examen complementario ",
        NAOPODEINSERIR: " no se pudo introducir para este paciente."
    },
    PACIENTE: {
        NOME: "Nombre",
        FONE: "Teléfono",
        CELULAR: "Teléfono móvil",
        SEXO: "Sexo",
        SELECIONARSEXO: "Selecciona tu sexo",
        FEM: "Femenino",
        MASC: "Masculino",
        NASCIMENTO: "Nacimiento",
        IDADE: "Edad",
        CALCULARIDADE: "Calcular la edad en",
        ANOS: "Años",
        MESES: "Meses",
        DIAS: "Días",
        PESO: "Peso (kg)",
        ALTURA: "Estatura (cm)",
        EMAIL: "Correo electrónico",
        RG: "Identificación.",
        CPF: "Seguro social",
        CONVENIO: "Tarjeta sanitaria nacional",
        INSCRICAO: "Inscripción",
        NOMEMAE: "Nombre de la madre",
        RACA: "Raza",
        ESPECIE: "Especie",
        RESPONSAVEL: "Responsable",
        OBSERVACOES: "Observaciones",
        USUARIO: "Usuario",
        CAMERA: "Cámara",
        CAMERAMENSAGEM: "Haga clic para capturar a través de la cámara web",
        ARQUIVO: "Archivo",
        USUARIOWEB: "Crear usuario web",
        CANCELARUSUARIO: "Cancelar usuario web",
        INDICACAO: "Indicación",
        SALVAR: "Guardar",
        CANCELAR: "Cancelar",
        MENSAGEMNASCIMENTO: "Valor no válido para la fecha de nacimiento.",
        MENSAGEMIDADE: "Valor inválido para la edad!",
        EMAILERRO: "El correo electrónico ",
        USADO: " ya está siendo utilizado por el ",
        CODIGOUSADO: " código ",
        RACAOBRIGATORIO: "Raza obligatoria.",
        ESPECIEOBRIGATORIO: "Especie obligatoria.",
        ESPECIEINVALIDA: "Especie no válida",
        RESPONSAVELOBRIGATORIO: "Responsable obligatoria",
        CPFOBRIGATORIO: "Seguro social obligatoria",
        USARIOWEBNAOCRIADO: "No es posible crear un usuario web sin correo electrónico.",
        CPFUSADO: "Este seguro social ya está en uso.",
        ERROSALVAR: "Error al guardar el paciente, verificando el seguro social: ",
        ERROSALVARPACIENTE: "Error al guardar el paciente: ",
    },
    BUSCACEP: {
        CEP: "Código postal",
        CEPMESSAGEM: "Código postal no encontrado",
        ENDERECO: "Dirección",
        BAIRRO: "Barrio",
    },
    BUSCACIDADE: {
        CIDADE: "Ciudad",
        NENHUMACIDADE: "Ninguna ciudad seleccionada",
    },
    EXAMESATRASADOS:{
        PEDIDO: "Solicitud", 
        REFERENCIA: "Referencia",
        PACIENTE: "Paciente",
        EXAMES: "Exámenes",
        MOTIVO: "Motivo",
    },
    INTEGRACAO: {
        TIPO: "Tipo",
        INTEGRADOR: "Integrador",
        DATA: "Fecha",
        MENSAGEM: "Mensaje",
    },
    WORKFLOW: {
        PACIENTE: "Paciente",
        DATARETORNO: "Fecha de regresso",
        ETIQUETA: "Etiqueta",
        IMPRIMIRETIQUETA: "Imprimir la etiqueta",
        COMPROVANTE: "Bono",
        IMPRIMIRCOMPROVANTE: "Imprimir bono",
        VINCULARETIQUETA: "Vinculación de las etiquetas de precolección",
        SALVAR: "Guardar",
        MENSAGEMVINCULO: "Enlace(s) completado(s) con éxito.",
        ERROIMPRIMIR: "Error al imprimir etiquetas: ",
    },
    COMPROVANTEVARIAVEL:{
        MENSAGEMIMPRESSAO: "Error al imprimir el bono: "
    },
    MODALUSUARIO: {
        CONFIGUSUARIO: "Configuración del usuario",
        VIDEOS: "Vídeos de formación",
        AGENTE: "Agente de impresión",
        ALTERARSENHA: "Cambiar contraseña",
        SAIRSISTEMA: "Salir del sistema",
        FECHAR: "Cerrar",
    },
    MODALIMPRESSAO: {
        IMPRESSAO: "Imprimir",
        CONEXAO: "Conexión",
        IMPRIMIR: "Impreso",
        FECHAR: "Cerrar",
        SALVAR: "Guardar",
    },
    MODALCANCELANDOEXAME: {
        CANCELAREXAME: "Cancelar examen",
        ATENCAO: "Atención!",
        AVISOCANCELAMENTO: "ste procedimiento cancelará los exámenes seleccionados",
        EXAMES: "Exámenes",
        MOTIVOS: "Razones",
        CONFIRMARCANCELAMENTO: "Confirmar cancelación",
        CANCELAR: "Cancelar",
    },
    MODALRESPOSTAPESQUISA: {
        NOMEARQUIVO: "Nombre del archivo",
        CLIQUEDOWNLOAD: "Haga clic para descargar el archivo",
        BAIXARARQUIVO: "Descargar archivo",
        ALTERARARQUIVO: "Alterar el archivo",
        SALVAR: "Guardar búsqueda",
    },
    GUIAEXAME: {
        RESULTADOVINCULADO: "Resultado del examen vinculado a la artículo ",
        GUIAVINCULADO: " de la guía ",
        EXCLUIREXAME: "Excluir el examen",
        LIGADESLIGAURGENCIA: "Activar/desactivar la urgencia",
        LIGADESLIGAMATERIAL: "Activar/desactivar la recepción del material",
        AUTORIZACAOCONVENIO: "Este examen requiere autorización de convenio!"
    },
    CADASTROGENERICO: {
        NENHUMEXAME: "No se han encontrado examen",
        TOTAL: "Total"
    }
}
<template>
    <section>
        <template v-if="editavel">
            <div class="panel">
                <b-checkbox-button v-model="guia.sepse" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-sepse">
                    <span>SEPSE</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.dorToracica" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-dortoracica">
                    <span>Dor torácica</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.avc" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-avc">
                    <span>AVC</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.traumatologia" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-traumatologia">
                    <span>Traumatologia</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.prestesAReceberAltaMedica" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-prestesareceberaltamedica">
                    <span>Prestes a Receber Alta Médica</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.guiaPendente" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-guiapendente">
                    <span>Guia Pendente</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.necessidadesEspeciais" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-necessidadesespeciais">
                    <span>Necessidades Especiais</span>
                </b-checkbox-button>
                <b-checkbox-button v-model="guia.guiaConferida" :native-value="true"
                                   size="is-small"
                                   type="guia-indicador-guiaconferida">
                    <span>Guia Conferida</span>
                </b-checkbox-button>
            </div>
        </template>
        <template v-else>
            <span v-if="guia.sepse" class="tag guia-indicador-sepse">
                SEPSE
            </span>
            <span v-if="guia.dorToracica" class="tag guia-indicador-dortoracica">
                Dor torácica
            </span>
            <span v-if="guia.avc" class="tag guia-indicador-avc">
                AVC
            </span>
            <span v-if="guia.traumatologia" class="tag guia-indicador-traumatologia">
                Traumatologia
            </span>
            <span v-if="guia.prestesAReceberAltaMedica" class="tag guia-indicador-prestesareceberaltamedica">
                Prestes a Receber Alta Médica
            </span>
            <span v-if="guia.guiaPendente" class="tag guia-indicador-guiapendente">
                Guia Pendente
            </span>
            <span v-if="guia.necessidadesEspeciais" class="tag guia-indicador-necessidadesespeciais">
                Necessidades Especiais
            </span>
            <span v-if="guia.guiaConferida" class="tag guia-indicador-guiaconferida">
                Guia Conferida
            </span>
        </template>
    </section>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            guia: Object,
            editavel: Boolean
        },
        computed: {
            ...mapState([
                'config'
            ]),
        },
    }
</script>
import store from '@/store';

export default {
    install(Vue) {
        const laborwebsocket = (function () {

            const _ws = {
                websocket: {
                    connection: null
                },

                displayError: function (err) {
                    console.error(err);
                    //store.commit("ADD_ERRO", "LaborWebsocket: " + err);
                    this.updateState("ERRO", "danger");
                },

                updateState: function (text, css) {
                    store.commit("laborlisWebsocket/SET_STATUS", { text, css });
                },

                tools: {
                    ws: typeof WebSocket !== "undefined" ? WebSocket : null
                }
            };

            return {
                config: {
                    address: store.state.laborlisWebsocket.address,
                    alternativeaddress: store.state.laborlisWebsocket.alternativeaddress
                },

                connect: function () {
                    try {

                        laborwebsocket.config.address = store.state.laborlisWebsocket.address;

                        console.log("Tentando conectar", laborwebsocket.config.address);
                        _ws.updateState("Aguardando", "light");
                        _ws.websocket.connection = new _ws.tools.ws(laborwebsocket.config.address);
                    } catch (e) {
                        _ws.displayError(e);
                    }

                    if (_ws.websocket.connection !== null) {

                        _ws.websocket.connection.onopen = function () {
                            console.log("LaborWebsocketServer conectado em " + laborwebsocket.config.address);
                            store.commit("laborlisWebsocket/SET_ADDRESS", laborwebsocket.config.address);
                            _ws.updateState("Ativa", "success");
                            window.localStorage.setItem('printing_lock', '');

                            laborwebsocket.listPrinters();
                        };

                        _ws.websocket.connection.onclose = function (evt) {
                            if (store.state.laborlisWebsocket.status === "Ativa") {
                                _ws.updateState("Inativa", "warning");
                            }
                            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                                _ws.websocket.connection.onerror(evt);
                            }
                        };

                        _ws.websocket.connection.onerror = function (err) {

                            if(laborwebsocket.config.alternativeaddress != null && laborwebsocket.config.alternativeaddress.length > 0)
                            {
                                let tryconection = true;
                                let count = 0;
                                while(tryconection)
                                {
                                    console.log("Tentando conectar", laborwebsocket.config.alternativeaddress[count]);
                                    _ws.updateState("Aguardando", "light");
    
                                    try {
                                        laborwebsocket.config.address = laborwebsocket.config.alternativeaddress[count];
                                        store.commit("laborlisWebsocket/SET_ADDRESS", laborwebsocket.config.address);
                                        _ws.websocket.connection = new _ws.tools.ws(laborwebsocket.config.alternativeaddress[count]);
                                        
                                    } catch (e2) {
                                        const a = "";
                                    }
                                    count++;
    
                                    if(count >= laborwebsocket.config.alternativeaddress.length){
                                        tryconection = false;
                                    }
                                }
                            }

                            console.error(err);
                            _ws.updateState("Erro", "danger");
                            if (err.target !== undefined) {
                                if (err.target.readyState === 2) {
                                    _ws.displayError("A conexão com LaborWebSocketServer foi fechada");
                                } else if (err.target.readyState === 3) {
                                    _ws.displayError("Não foi possível conectar ao LaborWebsocketServer");
                                } else {
                                    _ws.displayError("Ocorreu um erro de conexão, verifique o log para maiores detalhes");
                                }
                            } else {
                                _ws.displayError(err);
                            }
                        };

                        _ws.websocket.connection.onmessage = function (evt) {
                            const msg = JSON.parse(evt.data);

                            if (msg.Comando === "printerlist") {

                                const msgs = msg.Mensagem.split("\n");
                                for (let i = 0; i < msgs.length - 1; i++) {
                                    //percorre a lista e vai incluindo as options
                                    //aquelas que tem o // trocar por /
                                    msgs[i] = msgs[i].replace('\\\\\\\\', '-\\');
                                    msgs[i] = msgs[i].replace('\\\\', '\\');
                                    msgs[i] = msgs[i].replace('-\\', "\\\\");
                                }

                                store.commit("laborlisWebsocket/SET_IMPRESSORAS_DISPONIVEIS", msgs);
                                store.dispatch("laborlisWebsocket/getFilasImpressoras")
                            } else if (msg.Comando === "printraw") {
                                if (msg.Sucesso) {
                                    store.commit("laborlisWebsocket/REGISTRAR_LOG_IMPRESSAO_AMOSTRA", msg.Dados);
                                }
                            }
                        };

                    } else {
                        _ws.displayError("Não foi possível conectar ao LaborWebsocketServer!");
                    }

                },

                close: function () {
                    _ws.websocket.close();
                },

                displayError: function (err) {
                    _ws.displayError(err);
                },

                isActive: function () {
                    return _ws.websocket.connection !== null && (_ws.websocket.connection.readyState === 1);
                },

                listPrinters: function () {

                    if (!this.isActive()) {
                        _ws.displayError("Não há uma conexão ativa com o LaborWebsocketServer!");
                        _ws.updateState("Erro", "danger");
                        return;
                    }
                    try {
                        _ws.websocket.connection.send("###printerlist###");
                    } catch (e) {
                        _ws.websocket.connection.onerror(e);
                    }
                },

                print: function (impressora, impressoranome, linhas, dados) {
                    if (!this.isActive()) {
                        _ws.displayError("Não há uma conexão ativa com o LaborWebsocketServer!");
                        _ws.updateState("Erro", "danger");
                        return;
                    }
                    try {
                        _ws.websocket.connection.send("###printraw:" + impressoranome + "\n" + linhas + String.fromCharCode(3) + dados);
                        localStorage.setItem(impressora, impressoranome);
                    } catch (e) {
                        _ws.websocket.connection.onerror(e);
                    }
                },

                printPdf: function (impressora, impressoranome, url, token) {

                    if (!this.isActive()) {
                        _ws.displayError("Não há uma conexão ativa com o LaborWebsocketServer!");
                        _ws.updateState("Erro", "danger");
                        return;
                    }
                    try {
                        if (url === undefined || token === undefined) {
                            alert("Não há informações sobre o documento PDF.");
                            return;
                        }

                        const printCmd = "###printpdf:" + impressoranome + "\n" + url + "/" + token;
                        _ws.websocket.connection.send(printCmd);

                        localStorage.setItem(impressora, impressoranome);
                    } catch (e) {
                        _ws.websocket.connection.onerror(e);
                    }
                }

            };

        })();

        laborwebsocket.connect();

        Vue.prototype.$laborwebsocket = laborwebsocket;
    }
}
<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    O material está sendo salvo, este processo pode levar alguns minutos.
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div> 
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Códigos integrações
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Código</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select placeholder="Integrador" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input horario " type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        Adicionar código
                                    </button>
                                </div>
                            </article>
                        </article>                        
                    </div>                  
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('material-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,  
                    codigosIntegrador: []
                },
                salvandoDados: false
            }
        },
        created() {
            this.$http.get('/api/manutencao/material?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Material',
                    this.model.id ?? 'novo'
                ]
            },
            ...mapState([
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/material`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            }
        }
    }
</script>
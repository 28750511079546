<template>
    <div class="columns is-multiline" ref="lista">
        <div v-if="isLoading" class="column is-3">
            <div class="panel">
                <p class="panel-heading has-background-light">
                    <b-skeleton animated active height="24px"></b-skeleton>
                </p>
                <div class="panel-block">
                    <b-skeleton height="185px"></b-skeleton>
                </div>
                <div class="panel-block">
                    <b-skeleton animated active></b-skeleton>
                </div>
                <div class="panel-block columns">
                    <div class="column">
                        <b-skeleton animated active height="40px"></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active height="40px"></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active height="40px"></b-skeleton>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="item, index in equipamentos" v-bind:key="index" class="column is-3">
            <div class="panel">
                <p class="panel-heading has-background-light">
                    <span class="tag is-black">
                        {{item.equipmentId}}
                    </span>
                    {{item.equipmentName}}
                </p>
                <div class="panel-block has-image-centered" style="min-height:185px">
                    <figure class="has-image-centered">
                        <img :src="'api/Dashboard/EquipamentoImagem?modelId=' + item.equipmentModelId" />
                    </figure>
                </div>
                <p v-if="item.equipmentLocationId">
                    Local: {{item.equipmentLocationId}}
                </p>
                <p v-show="config.mostrarQuantidadePendenciasInterface" class="panel-block is-justified-between">
                    <span>Resultados a confirmar:</span>
                    <b>{{item.resultadosAConfirmar}}</b>
                </p>
                <div class="panel-block is-justified-between">
                    <router-link v-if="isInRole('interface')"
                                 :to="{ name: 'interfaceconfirm', params: { id: item.equipmentId }}"
                                 class="button is-success is-fullwidth"
                                 title="Confirmar resultados"
                                 :event="item.resultadosAConfirmar > 0 || !config.mostrarQuantidadePendenciasInterface ? 'click' : ''"
                                 :disabled="item.resultadosAConfirmar == 0 && config.mostrarQuantidadePendenciasInterface">
                        <b-icon icon="check-bold"></b-icon>
                    </router-link>
                    <!--<router-link v-if="isInRole('interface')" :to="{ name: 'interfacequalitycontrol', params: { id: item.equipmentId }}" class="button is-fullwidth" title="Confirmar resultados">
                        <b-icon icon="chart-areaspline"></b-icon>
                    </router-link>-->
                    <router-link v-if="isInRole('interface') && item.equipmentSampleLink" :to="{ name: 'interfacesamplelink', params: { id: item.equipmentId }}" class="button is-primary is-fullwidth" title="Vincular amostras">
                        <b-icon icon="link-variant"></b-icon>
                    </router-link>
                    <router-link v-if="isInRole('interface-configuracao')" :to="{ name: 'interfaceconfig', params: { id: item.equipmentId }}" class="button is-black is-fullwidth" title="Configurar equipamento">
                        <b-icon icon="cog"></b-icon>
                    </router-link>
                    <router-link :to="{ name: 'amostralotecheckpoint', params: { equipmentid: item.equipmentId }}" class="button is-warning is-fullwidth" title="Enviar worklist">
                        <b-icon icon="flag-variant"></b-icon>
                    </router-link>
                    <router-link v-if="isInRole('interface-manutencao')" :to="{ name: 'interfacemaintenancelist', params: { equipmentid: item.equipmentId }}" class="button is-danger is-fullwidth" title="Manutenções equipamento">
                        <b-icon icon="wrench"></b-icon>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                equipamentos: [],
                imagens: [],
                isLoading: true
            }
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            ...mapActions([
                'get',
            ]),
            carregarEquipamentos() {
                this.$http.get('api/Dashboard/EquipamentosAtivos?unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.equipamentos = res.data
                        this.isLoading = false
                    });
            }
        },
        mounted() {
            this.carregarEquipamentos();
        },
    }
</script>
<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <div class="columns box">
      <div class="column is-5">
        <b-field>
          <b-input
            placeholder="Procurar por nome"
            v-model="procurar"
            icon="magnify"
            @input="loadAsyncData"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <div class="is-flex is-justified-between">
          <router-link
            v-if="isInRole('Administrador')"
            :to="{ name: 'funcionario' }"
            class="button is-primary"
          >
            Incluir
          </router-link>
        </div>
      </div>
      <div class="column is-2">
        <form @submit.prevent="abrirCadastroInput">
          <b-field>
            <b-input
              placeholder="Código"
              v-model="id"
              type="number"
              icon-right="edit"
              icon-right-clickable
              @icon-right-click="abrirCadastroInput"
            >
            </b-input>
          </b-field>
        </form>
      </div>
    </div>
    <b-table
      :data="model.lista"
      :loading="loading"
      paginated
      striped
      hoverable
      backend-pagination
      :total="total"
      @page-change="onPageChange"
      aria-next-label="Próxima página"
      aria-previous-label="Página anterior"
      aria-page-label="Página"
      aria-current-label="Página atual"
      backend-sorting
      :default-sort-direction="defaultOrdenar"
      :default-sort="[ordenarPor, ordenar]"
      sort-icon-size="is-small"
      @sort="onSort"
    >
      <template slot-scope="props" @click="abrirCadastro(props.row.id)">
        <b-table-column field="id" label="Id">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="Nome" label="Nome">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column field="NivelAcesso" label="Nível de Acesso">
          {{ props.row.nivelAcesso }}
        </b-table-column>       

        <b-table-column field="Email" label="E-mail">
          {{ props.row.email }}
        </b-table-column>                

        <b-table-column custom-key="actions" numeric>
          <router-link
            :to="{ name: 'funcionario', params: { id: props.row.id } }"
            class="button is-small is-primary"
            title="Editar"
          >
            <b-icon icon="pencil" size="is-small"></b-icon>
          </router-link>
        </b-table-column>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import titlebar from "@/components/titlebar.vue";

export default {
  data() {
    return {
      model: [],
      total: 0,
      loading: false,
      procurar: this.$route.query.procurar ?? "",
      ordenarPor: "Nome",
      ordenar: "asc",
      defaultOrdenar: "asc",
      page: 1,
      perPage: 20,
      selecionado: null,
      id: null,
    };
  },
  components: {
    titlebar,
  },
  computed: {
    titleStack() {
      return ["Manutenção", "Funcionários"];
    },
    ...mapGetters(["isInRole"]),
  },
  methods: {
    loadAsyncData() {
      const params = [
        `procurar=${this.procurar}`,
        `ordem=${this.ordenarPor}.${this.ordenar}`,
        `page=${this.page}`,
      ].join("&");

      this.loading = true;
      this.$http
        .get(`/api/manutencao/funcionarios?${params}`)
        .then(({ data }) => {
          this.model = data;
          if (data.lista.length == 0 && this.page > 1) {
            this.page = 1;
            this.loadAsyncData();
          }
          this.total = this.perPage * data.pageCount;
          this.loading = false;
          this.$router.replace({
            query: { procurar: this.procurar, page: this.page },
          });
        })
        .catch((error) => {
          this.model = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort(field, order) {
      this.ordenarPor = field;
      this.ordenar = order;
      this.loadAsyncData();
    },
    abrirCadastro() {
      this.$router.push({ path: `/manutencao/funcionario/${this.selecionado.id}` });
    },
    abrirCadastroInput() {
      this.$router.push({ path: `/manutencao/funcionario/${this.id}` });
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

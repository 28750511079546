<template>
  <section>
    <b-loading :active.sync="isLoading"></b-loading>

    <titlebar :title-stack="titleStack"> </titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="tile is-ancestor">
          <div
            class="tile is-parent is-vertical"
            :class="{ 'is-9': model.id, 'is-12': !model.id }"
          >
            <article class="tile is-child box">
              <div class="columns">
                <div class="column">
                  <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                    <b-field label="Nome"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input v-model="model.nome" type="text"  maxlength="20" :has-counter="false" />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Banco">
                    <b-select
                      placeholder=""
                      v-model="model.banco"
                      expanded
                    >
                      <option
                        v-for="option in bancos"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <ValidationProvider rules="required" name="agência" v-slot="{ errors, valid }">
                    <b-field label="Agência"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">
                      <b-input
                        v-model="model.agencia"
                        type="text"  maxlength="10" :has-counter="false" 
                        expanded
                      >
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                </div>                
                <div class="column">
                  <ValidationProvider rules="required" name="número" v-slot="{ errors, valid }">
                    <b-field label="Número"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors">                    
                      <b-input
                        v-model="model.numero"
                        type="text"  maxlength="10" :has-counter="false" 
                        expanded
                      >
                      </b-input>
                    </b-field>
                  </ValidationProvider>
                </div>                                
              </div>

              <div class="columns">                
                <div class="column">
                  <b-field label="Código Cedente">
                    <b-input
                      v-model="model.codigoCedente"
                      type="text"  maxlength="16" :has-counter="false" 
                      expanded
                    >
                    </b-input>
                  </b-field>
                </div>                
                <div class="column">
                    <b-field label="CNPJ">
                        <b-input v-model="model.cnpj" v-mask="['##.###.###/####-##']"></b-input>
                    </b-field>
                </div>                                
              </div>

              <div class="columns">                
                <div class="column">
                  <b-field label="Nosso Número">
                    <b-input
                      v-model="model.nossoNumero"
                      type="text"  maxlength="16" :has-counter="false" 
                      expanded
                    >
                    </b-input>
                  </b-field>
                </div>                
                <div class="column">
                  <b-field label="Carteira">
                    <b-input
                      v-model="model.carteira"
                      type="text"  maxlength="3" :has-counter="false" 
                      expanded
                    >
                    </b-input>
                  </b-field>
                </div>                                
              </div>
            </article>           
          </div>

          <div class="tile is-parent">
            <article class="tile is-child">
              <article class="panel">                
                <div v-if="model.id">
                  <div class="panel-block" v-if="isInRole('contaCorrente-alterar')">
                    <a :href="'/Financa/Extrato?id=' + model.id" class="button is-fullwidth is-success">Extrato</a>
                  </div>
                </div>
              </article>
            </article>
          </div>
        </div>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-success"
                v-if="
                  isInRole('contaCorrente-alterar')
                "
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                Salvar
              </b-button>
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                Cancelar
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<style scoped>
.fix-size {
  max-height: 1.5rem;
  padding-top: 0rem;
}
.btn-action {
  margin: 0.2rem;
}

.label-parcela-cancelada {
  padding-top: 0.25rem;
}

.collapse-content {
  width: 100% !important;
}

.panel .control {
  max-width: 210px;
}

.centered {
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import moment from "moment";

export default {
  data() {
    return {
      model: {
        id: null,
        descricao: null,
        observacoes: null,
        dataInicial: null,
        dataFinal: null,
        especie: {
          id: 0,
        },
        contaCorrente: {
          id: 0,
        },
      },
      salvandoDados: false,
      isOpenModalData: false,
      localizarModalData: null,
      erroCadastro: [],
      bancos: [],
      isLoading: false,
    };
  },
  components: {
    titlebar
  },
  filters: {
    data: (valor) => {
      if (!valor) return null;

      return moment(valor).format("DD/MM/YYYY");
    },
  },
  created() {
    this.isLoading = true;
    let id = "";
    if (this.$route.params.id) {
      id = "?id=" + this.$route.params.id;
    }
    this.$http
      .get("/api/financeiro/ContaCorrente" + id)
      .then((res) => {
        const model = res.data?.item;
        this.bancos = res.data?.bancos;
        if (!model.banco){
          model.banco = this.bancos[0]?.value;
        }

        this.model = model;
        this.isLoading = false;
      })
      .catch((ex) => {
        this.isLoading = false;
      });
  },
  computed: {
    titleStack() {
      return [
        "Financeiro",
        "Conta Corrente",
        this.model.id ? this.model.id : "novo"
      ];
    },
    ...mapState(["unidadeId", "config", "integradores"]),
    ...mapGetters(["isInRole"]),
  },
  watch: {
    itemsSelecionados: function (novoValor) {
      if (novoValor) {
        this.items.push(...novoValor);
        this.recalcularTotal();
      }
    },
  },
  methods: {
    onSubmit() {
      this.$forceUpdate();
      this.isLoading = true;
      const newItem = this.getItem();
      this.$http
        .post(`/api/financeiro/contaCorrente`, newItem)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "financeiro" });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          if (err.body?.errors) {
            Object.keys(err.body.errors).forEach((x) => {
              this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
            });
          } else {
            const mensagem =
              err.status === 500
                ? "Foi encontrado um erro no servidor ao salvar o registro"
                : "Erro ao salvar registro";
            this.$buefy.toast.open({
              duration: 5000,
              message: mensagem,
              type: "is-danger",
              queue: false,
            });
          }
        });
    },
    getItem() {
      const novoItem = Object.assign({}, this.model);
      return novoItem;
    },
   
    abrirExtrato() {
      this.$router.push({
        name: "extrato",
        params: { id: this.model.id },
      });
    },    
  },
};
</script>
